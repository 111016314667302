import React, {FormEvent, useState} from "react";
import {useMultistepForm} from "../../components/useMultistepForm";
import {Form1} from "./Form1";
import {Form2} from "./Form2";
import {Form3} from "./Form3";
import {Form4} from "./Form4";
import {Form5} from "./Form5";
import {Form6} from "./Form6";
import {Form7} from "./Form7";
import Container from "@mui/material/Container";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {apiUrl} from "../../config/SiteConfig"
import {Helmet} from "react-helmet";


type FormData = {
    position: string
    size?: string
    picture?: File | undefined
    location: string
    postcode?: string
    settlement?: string
    address?: string
    casco: string
    insurance?: string
    plateNumber: string
    vin?: string
    manufacturer: string
    type: string
    year: string
    personOrCompany: string
    name: string
    phone: string
    email: string
    privacyPolicy: boolean
}

const INITIAL_DATA: FormData = {
    position: "",
    location: "",
    casco: "",
    plateNumber: "",
    manufacturer: "",
    type: "",
    year: "",
    personOrCompany: "",
    name: "",
    phone: "",
    email: "",
    privacyPolicy: false
}

function Calculator() {

    const navigate = useNavigate();

    const [data, setData] = useState(INITIAL_DATA)
    function updateFields(fields: Partial<FormData>) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }
    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next, goTo } =
        useMultistepForm([
            <Form1 {...data} updateFields={updateFields} />,
            <Form2 {...data} updateFields={updateFields} />,
            <Form3 {...data} updateFields={updateFields} />,
            <Form4 {...data} updateFields={updateFields} />,
            <Form5 {...data} updateFields={updateFields} />,
            <Form6 {...data} updateFields={updateFields} />,
            <Form7 {...data} updateFields={updateFields} />,
        ])

    function backStep() {
        if(currentStepIndex===2 && data.position!=='first'){
            return goTo(0)
        }else{
            return back()
        }
    }

    function onSubmit(e: FormEvent) {
        e.preventDefault()
        if(isFirstStep && data.position!=='first') return goTo(2)
        if (!isLastStep) return next()

        const postData = new FormData();
        postData.append('position', data.position)
        if(data.size != null) {
            postData.append('size', data.size)
        }else {
            postData.append('size', 'big')
        }
        if(data.picture != null) postData.append('picture', data.picture)
        postData.append('location', data.location)
        if(data.postcode != null) postData.append('postcode', data.postcode)
        if(data.settlement != null) postData.append('settlement', data.settlement)
        if(data.address != null) postData.append('address', data.address)
        postData.append('casco', data.casco)
        if(data.insurance != null) postData.append('insurance', data.insurance)
        postData.append('plateNumber', data.plateNumber)
        if(data.vin != null) postData.append('vin', data.vin)
        postData.append('manufacturer', data.manufacturer)
        postData.append('type', data.type)
        postData.append('year', data.year)
        postData.append('isCompany', (data.personOrCompany==='company')?"true":"false")
        postData.append('name', data.name)
        postData.append('phone', '+36'+data.phone)
        postData.append('email', data.email)
        postData.append('privacyPolicy', data.privacyPolicy?'true':'false')

        fetch(apiUrl+'/curiosity', {
            method: 'POST',
            body: postData,
        })
            .then((response) => response.json())
            .then((data) => {
                navigate('/kalkulator-thankyou.html')
                // Handle the response data
            })
            .catch((error) => {
                alert("Hiba a küldés során. Próbálja meg később!")
            });

    }

    return (
        <>
            <Helmet>
                <title>Online ajánlatkérő szélvédő javítás és csere | Glassmax</title>
                <meta name="description" content="Kérjen ajánlatot az autó és a sérülés adatainak megadásával pár egyszerű lépésben, és 24 órán belül megkapja írásos kalkulációnkat e-mailben." />
            </Helmet>
        <Container maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0
            }}
        >
            <form onSubmit={onSubmit}>
                <div style={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
                    {currentStepIndex + 1} / {steps.length}
                </div>
                {step}
                <div
                    style={{
                        marginTop: "1rem",
                        display: "flex",
                        gap: ".5rem",
                        justifyContent: "center",
                    }}
                >
                    {!isFirstStep && (
                        <Button type="button" onClick={backStep} sx={{borderRadius: 0,  alignSelf: 'center', background: '#000025', height: '40px', width: '200px', marginBottom: '10px', cursor: 'pointer', color: 'white', fontWeight: 'bold', '&:hover': {background: '#000025'} }}>
                            &lt; VISSZA
                        </Button>
                    )}
                    <Button type="submit" disabled={isLastStep && !data.privacyPolicy} sx={{borderRadius: 0,  alignSelf: 'center', background: '#ee312f', height: '40px', width: '200px', marginBottom: '10px', cursor: 'pointer', color: 'white', fontWeight: 'bold', '&:hover': {background: '#ee312f'} }}>{isLastStep ? "KÜLDÉS >" : "TOVÁBB >"}</Button>
                </div>
            </form>
        </Container>
        </>
    )

}

export default Calculator