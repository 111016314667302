import StockElement from "../../components/StockElement";


function StockKia() {
    return(
        <>
            <StockElement
                title="Kia szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Kia típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="KIA SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="CARENS, CARNIVAL, CEED, NIRO, OPTIMA, PROCEED, RIO, SOUL, PORTAGE,
                    STONIC, VENGA, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb KIA típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockKia