import {Box, Container, Grid, Link, Stack, Typography} from "@mui/material";
import BackgroundImage from "../assets/img/gradient.png";
import FleetImage from "../assets/img/glassmax_fleet.jpg";
import React from "react";

function Fleet() {
    return(
        <>
            <Container id="flotta" maxWidth={false} sx={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', paddingY: '20px', textAlign: 'center' }}>
                <Typography variant="h5" color="#ffffff">FLOTTA</Typography>
            </Container>
            <Grid container spacing={0} sx={{backgroundColor: "#000025",}}>
                <Grid item xs={12} sm={6} sx={{ backgroundImage: `url(${FleetImage})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', paddingY: '20px', textAlign: 'center', height: '500px' }}>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{height: '500px' }}
                    >
                        <Typography variant="body1" color="#ffffff" fontWeight="bold" sx={{padding: "15px"}}>Ön nagyban utazik? Mi is!</Typography>
                        <Typography variant="body1" color="#ffffff" sx={{padding: "15px"}}>Nem csak lakossági ügyfelek, de autókereskedések, céges flották igényeit is kiszolgáljuk egyedi árképzés mellett. Személyre szabott, üzleti ajánlatért hívjon bennünket!</Typography>
                        <Box component="div" className="sep" sx={{margin: "15px"}}></Box>
                        <Typography variant="body1" color="#ffffff" sx={{padding: "15px"}}>Kőfelverődés javítás, szélvédő csere, kamera kalibrálás, karosszéria üveg, napfénytető és hátsó üvegcsere. Akár mobilban a helyszínen kicseréljük!</Typography>
                        <Box sx={{backgroundColor: "#ee312f", padding: "15px"}}>
                            <Typography variant="body1" color="#ffffff">A legjobb budapesti árakkal, a legmodernebb technikával és teljeskörű kárügyintézéssel várjuk!</Typography>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default Fleet