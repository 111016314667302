import DoYouKnowDetail from "./DoYouKnowDetail";
import TopImage from "../assets/img/szelvedo_kalibralas.jpg"
import TopImageMobile from "../assets/img/szelvedo_kalibralas_mobile.jpg"
import useMediaQuery from "react-responsive";

function DoYouKnowWhyCalibrate() {
    const isMobile = useMediaQuery({ maxWidth: 600 })

    const imageUrl = isMobile  ? TopImageMobile : TopImage

    return(
        <>
            <DoYouKnowDetail
                title="Ha elmarad a kamera kalibrálás | Glassmax"
                meta="Fontos, hogy szélvédőcsere esetén a szélvédőkamera újrakalibrálásáról is gondoskodjunk. Előzzük meg a téves beavatkozást, és az abból adódó bajt."
                imageUrl={imageUrl}
                header="Miért kell a szélvédőn lévő kamerát szélvédő csere után kalibrálni?"
                text="<p>A szélvédő cseréje esetén az ADAS rendszert újra kell kalibrálni. Ha ennek elmaradása miatt téves
                    beavatkozás történik, aminek baleset a vége, az komoly problémát jelent nem csak a baleset ténye, de a
                    későbbi kárrendezés miatt is.</p>
                <p>Maga a kalibrálás kétféle módon történhet:</p>
                <p>
                <ul>
                    <li>Kalibrálás álló helyzetben: A gépjárművet egy speciális táblával szembe állítják, magát a folyamatot
                        a fedélzeti számítógépen keresztül végzik. A művelet viszonylag gyors, azonban speciális
                        követelmények vonatkoznak rá, illetve a berendezés ára meglehetősen magas.
                    </li>
                    <li>Kalibrálás menet közben: Egyes gépjárművek esetén menet közbeni kalibrálásra van szükség, amit
                        egyenes úton, adott sebességgel kell elvégezni.
                    </li>
                </ul>
                </p>
                <p>A szélvédőkamerák professzionális kalibrálásához speciális rendszerekre van szükség.
                    A Glass Max Hungary Kft olyan modern eszközökkel rendelkezik, amelyekkel minden autómárkához elvégezhető
                    a szélvédő kamera pontos beállítása. Munkánk során a TEXA rendszerét használjuk.</p>"/>
        </>
    )
}

export default DoYouKnowWhyCalibrate