import React, {useEffect} from 'react'
import { ThemeProvider, createTheme } from "@mui/material/styles"
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import './App.css'
import Root from "./pages/Root"
import FAQ from "./pages/FAQ"
import DoYouKnow from "./pages/DoYouKnow"
import DoYouKnowWhyCalibrate from "./components/DoYouKnowWhyCalibrate";
import DoYouKnowWhyDamageGlass from "./components/DoYouKnowWhyDamageGlass";
import DoYouKnowNeedCalibrate from "./components/DoYouKnowNeedCalibrate";
import DoYouKnowTypeGlass from "./components/DoYouKnowTypeGlass";
import DoYouKnowAfterReplacement from "./components/DoYouKnowAfterReplacement";
import DoYouKnowImportant from "./components/DoYouKnowImportant";
import DoYouKnowWindscreenFix from "./components/DoYouKnowWindscreenFix";
import Stock from "./pages/Stock"
import StockAlfaRomeo from "./pages/stock/StockAlfaRomeo"
import StockAudi from "./pages/stock/StockAudi"
import StockBmw from "./pages/stock/StockBmw"
import StockCitroen from "./pages/stock/StockCitroen"
import StockFiat from "./pages/stock/StockFiat"
import StockFord from "./pages/stock/StockFord"
import StockHonda from "./pages/stock/StockHonda"
import StockHyundai from "./pages/stock/StockHyundai"
import StockKia from "./pages/stock/StockKia"
import StockMazda from "./pages/stock/StockMazda"
import StockMercedes from "./pages/stock/StockMercedes"
import StockMitsubishi from "./pages/stock/StockMitsubishi"
import StockNissan from "./pages/stock/StockNissan"
import StockOpel from "./pages/stock/StockOpel"
import StockPeugeot from "./pages/stock/StockPeugeot"
import StockRenault from "./pages/stock/StockRenault"
import StockSkoda from "./pages/stock/StockSkoda"
import StockSsangyong from "./pages/stock/StockSsangyong"
import StockSuzuki from "./pages/stock/StockSuzuki"
import StockTesla from "./pages/stock/StockTesla"
import StockToyota from "./pages/stock/StockToyota"
import StockVolkswagen from "./pages/stock/StockVolkswagen"
import StockVolvo from "./pages/stock/StockVolvo"
import Footer from "./components/Footer"
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import ScrollToAnchor from "./components/ScrollToAnchor";
import Calculator from "./pages/calculator/Calculator";
import CalculatorThankYou from "./pages/calculator/CalculatorThankYou";
import Booking from "./components/Booking";
import ReactGA from "react-ga4";
import Carrier from "./components/Carrier"
import Carrier2 from "./components/Carrier2";
import Carrier3 from "./components/Carrier3";
import BookingThankYou from "./pages/booking/BookingThankYou";

const theme = createTheme({
    typography: {
        "fontFamily": `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        "fontWeightBold": 700
    },
    palette: {
        primary: {
            main: "#000025"
        },
        secondary: {
            main: "#ee312f"
        }
    }
});

const TRACKING_ID =  "G-7SG3X5FNN2";  //"G-XXXXXXXXX";

function App() {
    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
    }, []);

  return (
      <ThemeProvider theme={theme}>
          <Router>
              <div className="App">
                  <ScrollToAnchor/>
                  <ResponsiveAppBar/>
                  <Routes>
                      <Route path="/keszletunk.html" element=<Stock/> />
                      <Route path="/keszletunk/alfa-romeo.html" element=<StockAlfaRomeo/> />
                      <Route path="/keszletunk/audi.html" element=<StockAudi/> />
                      <Route path="/keszletunk/bmw.html" element=<StockBmw/> />
                      <Route path="/keszletunk/citroen.html" element=<StockCitroen/> />
                      <Route path="/keszletunk/fiat.html" element=<StockFiat/> />
                      <Route path="/keszletunk/ford.html" element=<StockFord/> />
                      <Route path="/keszletunk/honda.html" element=<StockHonda/> />
                      <Route path="/keszletunk/hyundai.html" element=<StockHyundai/> />
                      <Route path="/keszletunk/kia.html" element=<StockKia/> />
                      <Route path="/keszletunk/mazda.html" element=<StockMazda/> />
                      <Route path="/keszletunk/mercedes.html" element=<StockMercedes/> />
                      <Route path="/keszletunk/mitsubishi.html" element=<StockMitsubishi/> />
                      <Route path="/keszletunk/nissan.html" element=<StockNissan/> />
                      <Route path="/keszletunk/opel.html" element=<StockOpel/> />
                      <Route path="/keszletunk/peugeot.html" element=<StockPeugeot/> />
                      <Route path="/keszletunk/renault.html" element=<StockRenault/> />
                      <Route path="/keszletunk/skoda.html" element=<StockSkoda/> />
                      <Route path="/keszletunk/ssangyong.html" element=<StockSsangyong/> />
                      <Route path="/keszletunk/suzuki.html" element=<StockSuzuki/> />
                      <Route path="/keszletunk/tesla.html" element=<StockTesla/> />
                      <Route path="/keszletunk/toyota.html" element=<StockToyota/> />
                      <Route path="/keszletunk/volkswagen.html" element=<StockVolkswagen/> />
                      <Route path="/keszletunk/volvo.html" element=<StockVolvo/> />
                      <Route path="/kalkulator.html" element=<Calculator/> />
                      <Route path="/kalkulator-thankyou.html" element=<CalculatorThankYou/> />
                      <Route path="/tudta.html" element=<DoYouKnow/> />
                      <Route path="/miert_kell_a_szelvedon_levo_kamerat_szelvedo_csere_utan_kalibralni.html" element=<DoYouKnowWhyCalibrate/> />
                      <Route path="/miert_fontos_foglalkozni_a_serult_autouveggel.html" element=<DoYouKnowWhyDamageGlass/> />
                      <Route path="/honnan_tudhatja_hogy_szelvedocsere_utan_kalibralasra_van_szuksege.html" element=<DoYouKnowNeedCalibrate/> />
                      <Route path="/milyen_tipusu_szelvedokkel_talalkozhatunk.html" element=<DoYouKnowTypeGlass/> />
                      <Route path="/szelvedocsere_utan.html" element=<DoYouKnowAfterReplacement/> />
                      <Route path="/szelvedocsere_erre_kiemelt_figyelmet_kell_forditani.html" element=<DoYouKnowImportant/> />
                      <Route path="/szelvedo_javitas.html" element=<DoYouKnowWindscreenFix/> />
                      <Route path="/gyik.html" element=<FAQ/> />
                      <Route path="/karrier-autouveg-beepito.html" element=<Carrier/> />
                      <Route path="/karrier.html" element=<Carrier2/> />
                      <Route path="/karrier_zoldterulet_karbantarto.html" element=<Carrier3/> />
                      <Route path={"/booking/:id"} element=<Booking/> />
                      <Route path={"/booking-thankyou.html"} element=<BookingThankYou/> />
                      <Route path="/" element=<Root/> />
                  </Routes>
                  <Footer/>
              </div>
          </Router>
      </ThemeProvider>
  );
}

export default App;
