import StockElement from "../../components/StockElement";


function StockSkoda() {
    return(
        <>
            <StockElement
                title="Škoda szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Skoda típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="ŠKODA SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="CITIGO, FABIA, FAVORIT, KAMIQ, KODIAQ, OCTAVIA, PRAKTIK, RAPID,
                    ROOMSTER, SCALA, SUPERB, YETI, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb ŠKODA típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockSkoda