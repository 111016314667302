import { FormWrapper } from "./FormWrapper"
import {Container, Stack, Typography} from "@mui/material";
import CustomRadio from "../../components/CustomRadio";
import Image1 from "../../assets/img/glassmax_kalkulator_casco_yes.jpg";
import Image2 from "../../assets/img/glassmax_kalkulator_casco_no.jpg";
import React, {useState} from "react";

type CascoData = {
    casco: string
    insurance?: string
}

type InsuranceFormProps = CascoData & {
    updateFields: (fields: Partial<CascoData>) => void
}

export function Form4({
                                casco,
                                insurance,
                                updateFields,
                            }: InsuranceFormProps) {

    const [selectedValue, setSelectedValue] = useState(casco);
    const [showDetails, setShowDetails] = useState(casco==='yes');
    const [insuranceValue, setInsuranceValue] = useState(insurance);

    const handleRadioChange = (value: string) => {
        setSelectedValue(value);
        updateFields({casco: value})
        setShowDetails(value === 'yes')
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setInsuranceValue(event.target.value);
        updateFields({insurance: event.target.value})
    };


    return (
        <FormWrapper title="KALKULÁCIÓ - BIZTOSÍTÁS">
            <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}}}>
                Amennyiben CASCO biztosítással rendelkezik, cégünknél általában az Önrész megfizetése nélkül cseréltethet szélvédőt.
                A kárfelvétel után, a munka végeztével a költségeket a biztosítási ügyintézés keretében cégünk rendezi a biztosítóval.
            </Typography>
            <Stack direction={{xs: 'column', md: 'row'}} spacing={5} alignItems="center" justifyContent="center">
                <CustomRadio
                    autoFocus
                    name="casco"
                    value="yes"
                    imageSrc={Image1}
                    checked={selectedValue === "yes"}
                    onChange={() => handleRadioChange("yes")}
                />
                <CustomRadio
                    name="casco"
                    value="no"
                    imageSrc={Image2}
                    checked={selectedValue === "no"}
                    onChange={() => handleRadioChange("no")}
                />
            </Stack>
            {showDetails &&
            <Container style={{ marginLeft: 'auto', marginRight: 'auto'}}>
                <Stack direction="column" spacing={3}>
                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}, textAlign: 'left'}}>Biztosító:</Typography>
                    <select id="insurance" value={insuranceValue} onChange={handleSelectChange}>
                        <option value="Alfa">Alfa</option>
                        <option value="Allianz">Allianz</option>
                        <option value="CIG Pannonia">CIG Pannonia</option>
                        <option value="Generali">Generali</option>
                        <option value="Genertel">Genertel</option>
                        <option value="Groupama">Groupama</option>
                        <option value="K&amp;H">K&amp;H</option>
                        <option value="MKB">MKB</option>
                        <option value="Köbe">Köbe</option>
                        <option value="Porsche">Porsche</option>
                        <option value="Posta">Posta</option>
                        <option value="Signal Iduna">Signal Iduna</option>
                        <option value="Union">Union</option>
                        <option value="Uniqa">Uniqa</option>
                        <option value="Waberers">Waberers</option>
                    </select>
                </Stack>
            </Container>
            }
        </FormWrapper>
    )
}