import StockElement from "../../components/StockElement";


function StockSuzuki() {
    return(
        <>
            <StockElement
                title="Suzuki szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Suzuki típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="SUZUKI SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="ALTO, BALENO, GRAND VITARA, IGNIS, JIMNY, SPLASH, SWIFT, SX4, SX4-
                    CROSS, VITARA, WAGONR, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb SUZUKI típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockSuzuki