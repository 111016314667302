import StockElement from "../../components/StockElement";


function StockMitsubishi() {
    return(
        <>
            <StockElement
                title="Mitsubishi szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Mitsubishi típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="MITSUBISHI SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="ASX, COLT, ECLIPSE, GALANT, LANCER, OUTLANDER, PAJERO, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb MITSUBISHI típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockMitsubishi