import { FormWrapper } from "./FormWrapper"
import {Box, Container, Stack, Typography} from "@mui/material";
import {useState} from "react";

type PersonalData = {
    personOrCompany: string
    name: string
    phone: string
    email: string
}

type PersonalFormProps = PersonalData & {
    updateFields: (fields: Partial<PersonalData>) => void
}

export function Form6({
                          personOrCompany,
                          name,
                          phone,
                          email,
                          updateFields,
                      }: PersonalFormProps) {

    const [selectedValue, setSelectedValue] = useState(personOrCompany);

    const handleRadioChange = (value: string) => {
        setSelectedValue(value);
        updateFields({personOrCompany: value})
    };


    return (
        <FormWrapper title="KALKULÁCIÓ - SZEMÉLYES ADATOK">
            <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}}}>
                Kérjük, adja meg elérhetőségeit:
            </Typography>
            <Container style={{ marginLeft: 'auto', marginRight: 'auto'}}>
                <Stack direction="column" spacing={3}>
                    <Stack direction="column" textAlign="left">
                        <label>
                            <input type="radio"
                                   autoFocus
                                   required
                                   name="personOrCompany"
                                   value="person"
                                   checked={selectedValue === 'person'}
                                   onChange={() => handleRadioChange("person")}
                                   className=""/>Magánszemély
                        </label>
                        <label>
                            <input type="radio"
                                   required
                                   name="personOrCompany"
                                   value="company"
                                   checked={selectedValue === 'company'}
                                   onChange={() => handleRadioChange("company")}
                                   className=""/>Cég
                        </label>
                    </Stack>
                    <Typography sx={{
                        typography: {sm: 'body1', xs: 'body2'},
                        fontWeight: {xs: 'bold', sm: 'bold'},
                        textAlign: 'left'
                    }}>Név:</Typography>
                    <input
                        required
                        type="text"
                        value={name}
                        onChange={e => updateFields({name: e.target.value})}
                    />
                    <Typography sx={{
                        typography: {sm: 'body1', xs: 'body2'},
                        fontWeight: {xs: 'bold', sm: 'bold'},
                        textAlign: 'left'
                    }}>Telefonszám:</Typography>
                    <Stack direction="row" flexDirection="row">
                        <div>+36</div>
                        <input
                            required
                            type="text"
                            value={phone}
                            pattern="(\d){0,9}"
                            onChange={e => updateFields({phone: e.target.value})}
                            style={{ flex: 1, marginLeft: '8px' }}
                        />
                    </Stack>
                    <Typography sx={{
                        typography: {sm: 'body1', xs: 'body2'},
                        fontWeight: {xs: 'bold', sm: 'bold'},
                        textAlign: 'left'
                    }}>Email cím:</Typography>
                    <input
                        required
                        type="email"
                        value={email}
                        onChange={e => updateFields({email: e.target.value})}
                    />
                </Stack>
            </Container>
        </FormWrapper>
    )
}