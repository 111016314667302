import {Box, Container, Grid, Typography} from "@mui/material";
import React from "react";
import AboutItem from "./AboutItem";
import AboutitemIcon1 from "../assets/icon/szakertelem.png"
import AboutitemIcon2 from "../assets/icon/szeles_valasztek.png"
import AboutitemIcon3 from "../assets/icon/gyorsasag.png"
import AboutitemIcon4 from "../assets/icon/garancia_es_ugyintezes.png"


function About() {
    return(
        <>
            <Container  id="rolunk" sx={{ textAlign: 'center' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h4">RÓLUNK</Typography>
                        <Box className="sep" sx={{marginX: 'auto'}}/>
                        <Typography variant="body1">Minden alkalommal megpróbáljuk megjavítani a kőfelverődés okozta
                            kárt a szélvédőn,
                            előfordulhat hogy a sérülés túl nagy méretű ezért biztonsági okokból szükség van a szélvédő cseréjére.
                            Naprakész és tapasztalt szerelő
                            kollégáink mindent megtesznek, hogy Ön mihamarabb visszatérhessen az utakra! </Typography>
                        <Typography variant="body1"></Typography>
                        <Typography variant="body1">
                            Nincs ideje bejönni a műhelybe? Akkor mi megyünk Önhöz!
                            Mobil szerviz szolgáltatásunk már egész Pest megyében elérhető.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AboutItem img={AboutitemIcon1} title="SZAKÉRTELEM" text="A német Pro Glass kizárólagos magyarországi partnereként kollégáink csak prémium eszközökkel és
                    a legmodernebb technikával dolgoznak.
                    Kamerás szélvédőjû autók esetén a TEXA számítógépes program segítségével kalibrálnak." linkUrl="/honnan_tudhatja_hogy_szelvedocsere_utan_kalibralasra_van_szuksege.html" linkText="Honnan
                    tudhatja, hogy szélvédőcsere után kalibrálásra van szüksége?"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AboutItem img={AboutitemIcon2} title="SZÉLES VÁLASZTÉK" text="Mi nem csak személyautókhoz, de haszongépjárművekhez, kamionokhoz is kínálunk
                    szélvédőket. Tudjuk, hogy az idő pénz, ezért mindent megteszünk azért,
                    hogy Ön mihamarabb újra úton lehessen! Ha már a választékról beszélünk, a luxus és az oldtimer
                    autók sem lóghatnak ki a sorból." linkUrl="/milyen_tipusu_szelvedokkel_talalkozhatunk.html" linkText="Milyen típusú szélvédők léteznek?"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AboutItem img={AboutitemIcon3} title="GYORSASÁG" text="Egy repedt szélvédő sosem jön jókor. A Glassmax-nál mindent elkövetünk, hogy gyorsan
                    orvosoljuk a problémát. A kőfelverődés javítása 15 percet vesz igénybe,
                    a szélvédő cseréje 60-90 perc, a teljes száradással együtt 3 óra. Igény szerint mi megyünk az
                    autójához. Kőfelverődés javítása akár aznap, szélvédő csere 1 munkanapon belül lehetséges." />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AboutItem img={AboutitemIcon4} title="GARANCIA ÉS ÜGYINTÉZÉS" text="Hosszú évek óta kiváló kapcsolatot tartunk fenn az összes biztosító társasággal. Így
                    teljes körű CASCO ügyintézést tudunk biztosítani ügyfeleinknek.
                    A beszerelt szélvédőüveg hibájából előforduló eltérésekre, beázásra fél év garanciát
                    vállalunk." />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default About