import StockElement from "../../components/StockElement";


function StockFiat() {
    return(
        <>
            <StockElement
                title="Fiat szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Fiat típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="FIAT SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="500, BRAVA, BRAVO, BARCHETTA, DAILY, DOBLO, DUCATO, FIORINO, GRANDE
                    PUNTO, PANDA, PUNTO, SCUDO, STILO, TALENTO, TIPO, ULYSSE, UNO, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb FIAT típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockFiat