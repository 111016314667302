import DoYouKnowMainElement from "../components/DoYouKnowMainElement";
import Container from "@mui/material/Container";
import Picture1 from "../assets/img/szelvedo_kalibralas_half.jpg"
import Picture2 from "../assets/img/glassmax_tudta_half_2.jpg"
import Picture3 from "../assets/img/glassmax_tudta_half_3.jpg"
import Picture4 from "../assets/img/szelvedok_tipusai_half.jpg"
import Picture5 from "../assets/img/glassmax_tudta_half_5.jpg"
import Picture6 from "../assets/img/szelvedo_regisztracios_matrica_half.jpg"
import Picture7 from "../assets/img/szelvedo_javitas_gecko_keszulek_half.jpg"
import {Helmet} from "react-helmet";
import React from "react";
function DoYouKnow(){
    return(
        <>
            <Helmet>
                <title>Hasznos tudnivalók | Glassmax</title>
                <meta name="description" content="Néhány fontos tudnivaló, ami szélvédő cserekor vagy javításkor felmerülhet. Kamera kalibrálás, utógondozási tippek, a szélvédők típusai és még sok minden más." />
            </Helmet>
            <Container className="content" sx={{marginTop: '60px'}}>
                <DoYouKnowMainElement
                    odd={false}
                    imageSrc={Picture1}
                    title="Miért kell a szélvédőn lévő kamerát szélvédő csere után kalibrálni?"
                    text="A szélvédő cseréje esetén az ADAS rendszert újra kell kalibrálni. Ha ennek elmaradása miatt téves
                beavatkozás történik, aminek baleset a vége, az komoly problémát jelent nem csak a baleset ténye, de a
                későbbi kárrendezés miatt is."
                    url="/miert_kell_a_szelvedon_levo_kamerat_szelvedo_csere_utan_kalibralni.html"/>
                <DoYouKnowMainElement
                    odd={true}
                    imageSrc={Picture2}
                    title="Miért fontos foglalkozni a sérült autóüveggel?"
                    text="A szélvédő nem csak a szél és az időjárás viszontagságai ellen nyújt védelmet. A
            modern autók könnyűszerkezetes felépítése egyre inkább magába foglalja a szélvédőt teljes konstrukcióban. A
            szélvédő így a jármű szerkezetének alapvető elemévé válik, és a jármű karosszériájának szerves részét
            képezi."
                    url="/miert_fontos_foglalkozni_a_serult_autouveggel.html"/>
                <DoYouKnowMainElement
                    odd={false}
                    imageSrc={Picture3}
                    title="Honnan tudhatja, hogy szélvédőcsere után kalibrálásra van szüksége?"
                    text="A legtöbb új jármű rendelkezik ADAS, vagyis vezetéstámogató funkciókkal. Ahogy az autógyártás fejlődik,
                már az olcsóbb modellekbe is egyre több kényelmi tulajdonságot építenek be."
                    url="/honnan_tudhatja_hogy_szelvedocsere_utan_kalibralasra_van_szuksege.html"/>
                <DoYouKnowMainElement
                    odd={true}
                    imageSrc={Picture4}
                    title="Milyen típusú szélvédőkkel találkozhatunk?"
                    text="Míg edzett üveget leggyakrabban az oldalsó ablakokhoz és a hátsó szélvédőhöz
            használnak, addig az első szélvédő laminált üvegből készül. Két üvegréteg közé egy fólia kerül, amely
            megakadályozza, hogy a szélvédő ütközéskor, vagy baleset esetén berobbanjon."
                    url="/milyen_tipusu_szelvedokkel_talalkozhatunk.html"/>
                <DoYouKnowMainElement
                    odd={false}
                    imageSrc={Picture5}
                    title="Szélvédőcsere után - utógondozási tippek:"
                    text="Az újonnan beszerelt üvegek kezelésének nagy szerepe van a szélvédő hosszú élettartamában, újszerű állapotának megőrzésében."
                    url="/szelvedocsere_utan.html"/>
                <DoYouKnowMainElement
                    odd={true}
                    imageSrc={Picture6}
                    title="Szélvédőcsere? Erre kiemelt figyelmet kell fordítani!"
                    text="A szélvédőn lévő autópálya- és regisztrációs matricák levételekor sérülhetnek.
                Az ügyfél felelősségére a regisztrációs matricát, amennyiben lehetséges, megfelelő minőségben
                átragasztjuk az új szélvédőre vagy, kérésére, egy fólián átadjuk."
                    url="/szelvedocsere_erre_kiemelt_figyelmet_kell_forditani.html"/>
                <DoYouKnowMainElement
                    odd={false}
                    imageSrc={Picture7}
                    title="A szélvédő javítás célja és menete"
                    text="A kavicsfelverődés és a jégeső okozta kár nem csupán optikai hiba!
                Ezért a műszaki vizsgán, illetve közúti ellenőrzés során is odafigyelnek a szélvédő hibáira."
                    url="/szelvedo_javitas.html"/>
            </Container>
        </>
    )
}

export default DoYouKnow