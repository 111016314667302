import {Box, Container, Stack, Typography} from "@mui/material";
import React from "react";

function Casco() {
    return(
        <>
            <Container id="casco" sx={{ textAlign: 'center' }}>
                <Stack spacing={5}>
                    <Stack spacing={3} alignItems="center">
                       <Typography component="h2" variant="h5" gutterBottom>CASCO</Typography>
                        <Box className="sep" sx={{marginX: 'auto'}}/>
                        <Typography variant="body1">Amennyiben CASCO biztosítással rendelkezik, cégünknél általában az Önrész megfizetése nélkül
                            cseréltethet szélvédőt.
                            A helyszíni kárfelvétel után, a munka végeztével a költségeket a biztosítási ügyintézés
                            keretében cégünk rendezi a biztosítóval.</Typography>
                        <Typography variant="body1">NINCS Casco biztosítása? Hívjon, hogy mielőbb árat adhassunk! 15 000 darabos raktárkészletünknek
                            köszönhetően nálunk gyári minőségû utángyártott szélvédők közül válogathat.</Typography>
                    </Stack>
                    <Stack id="garancia" spacing={3} alignItems="center">
                        <Typography component="h3" variant="h5" gutterBottom>GARANCIA</Typography>
                        <Box className="sep" sx={{marginX: 'auto'}}/>
                        <Typography variant="body1">A beszerelt szélvédőüveg hibájából előforduló eltérésekre, beázásra
                            1 év garanciát vállalunk.
                            A kavicsfelverődésből eredő sérülés nem számít a garanciavállalás alapjául!</Typography>
                        <Typography variant="body1" fontWeight="bold">A szélvédőüveg rögzítését biztosító
                            ragasztóanyag megfelelő kötése érdekében az alábbiak betartását kérjük:</Typography>

                        <Typography variant="body1">A gépjármű átvételét követő 24 órán belül ne távolítsa el a szélvédő rögzítését biztosító
                            ragasztószalag csíkot.</Typography>
                        <Typography variant="body1">Az átvételt követő 24 órán belül ne mossa, vagy mosassa le a gépkocsit.</Typography>

                        <Typography variant="body1">A karosszéria feszüléséből adódó meghibásodás elkerülése érdekében, az átvételt követő 24
                            órán belül lehetőség szerint sík terepen parkoljon, ne álljon fel két kerékkel a járdára.</Typography>
                        <Typography variant="body1">Az átvételt követő 24 órán belül, kerülendő az ajtók erős becsapása.</Typography>

                        <Typography variant="body1">Fontos: A szélvédő csere során használt ragasztó teljes átkötési ideje 48 óra.
                            Ennek leteltéig ne vigye az autót műszaki vizsgára, vagy szervízbe. Ha az autót rázópadra teszik, vagy felemelik a
                            ragasztó kötési ideje alatt, az akár a kaszni deformálódásához is vezethet.</Typography>
                        <Typography variant="body1">A fenti előírások nem megfelelő betartásából eredő meghibásodás
                            esetén, a szélvédőüveg beszerelésére vonatkozó garanciát nem vállalunk!
                            A gépjárművet leadás után maximum 3 napig tároljuk díjmentesen telepünkön.</Typography>


                    </Stack>

                </Stack>
            </Container>
        </>
    )
}

export default Casco