import { FormWrapper } from "./FormWrapper"
import {Box, Container, Stack, Typography, useMediaQuery} from "@mui/material";
import CustomRadio from "../../components/CustomRadio";
import Image1 from "../../assets/img/glassmax_kalkulator_location_site.jpg";
import Image2 from "../../assets/img/glassmax_kalkulator_location_customer.jpg";
import {useState} from "react";

type LocationData = {
    location: string
    postcode?: string
    settlement?: string
    address?: string
}

type LocationFormProps = LocationData & {
    updateFields: (fields: Partial<LocationData>) => void
}

export function Form3({
                          location,
                          postcode,
                          settlement,
                          address,
                          updateFields,
                      }: LocationFormProps) {

    const [selectedValue, setSelectedValue] = useState(location);
    const [showDetails, setShowDetails] = useState(location==='customer');

    const handleRadioChange = (value: string) => {
        setSelectedValue(value);
        updateFields({location: value})
        if(value === 'customer'){
            setShowDetails(true)
        }
        else {
            setShowDetails(false)
        }
    };

    return (
        <FormWrapper title="KALKULÁCIÓ - JAVÍTÁS HELYE">
            <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}}}>
                Javítás / csere a Glassmax telephelyén (Budapest XV. kerületi szervíz), vagy az Ön által megadott helyszínen <br/>
                ( A mobilszervíz szolgáltatás csak Budapesten és 100km-es körzetében érhető el ).
            </Typography>
            <Stack direction={{xs: 'column', md: 'row'}} spacing={5} alignItems="center" justifyContent="center">
                <CustomRadio
                    autoFocus
                    name="location"
                    value="site"
                    imageSrc={Image1}
                    checked={selectedValue === "site"}
                    onChange={() => handleRadioChange("site")}
                />
                <CustomRadio
                    name="location"
                    value="customer"
                    imageSrc={Image2}
                    checked={selectedValue === "customer"}
                    onChange={() => handleRadioChange("customer")}
                />
            </Stack>
            { showDetails &&
            <Container style={{ marginLeft: 'auto', marginRight: 'auto'}}>
            <Stack direction="column" spacing={3}>

                <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}, textAlign: 'left'}}>Irányítószám:</Typography>
                <input
                    autoFocus
                    required={location==='customer'}
                    type="text"
                    value={postcode}
                    onChange={e => updateFields({postcode: e.target.value})}
                />
                <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}, textAlign: 'left'}}>Település:</Typography>
                <input
                    required={location==='customer'}
                    type="text"
                    value={settlement}
                    onChange={e => updateFields({settlement: e.target.value})}
                />
                <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}, textAlign: 'left'}}>Cím:</Typography>
                <input
                    required={location==='customer'}
                    type="text"
                    value={address}
                    onChange={e => updateFields({address: e.target.value})}
                />
            </Stack>
            </Container>
            }
        </FormWrapper>
    )
}