import StockElement from "../../components/StockElement";


function StockMazda() {
    return(
        <>
            <StockElement
                title="Mazda szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Mazda típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="MAZDA SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="2, 3, 5, 6, 323, 626, CX-3, CX-5, MX-3, MX-5, MX-6, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb MAZDA típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockMazda