import React from "react";
import {NavLink} from "react-router-dom";
import {Box, Container, Stack, Typography} from "@mui/material";
import BackIcon from "../assets/icon/back.png"
import {Helmet} from "react-helmet";

type DoYouKnowProps = {
    title: string,
    meta: string,
    imageUrl: string,
    header: string,
    text: string
}

function DoYouKnowDetail({title, meta, header, text, imageUrl}: DoYouKnowProps) {
    return(
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={meta} />
            </Helmet>
            <Container className="content">
                <Stack>
                    <Box component="img" src={imageUrl} sx={{ marginY: '20px'}}></Box>
                    <Typography component="h1" variant="h4" fontWeight="bold" gutterBottom>{header}</Typography>
                    <Box component="div" className="sep"/>
                    <Typography gutterBottom>
                        <div dangerouslySetInnerHTML={{ __html: text }}/>
                    </Typography>
                    <NavLink to="/tudta.html"><Box component="img" src={BackIcon} width="150px"/></NavLink>
                </Stack>
            </Container>
        </>
    )
}

export default DoYouKnowDetail