import DoYouKnowDetail from "./DoYouKnowDetail";
import Image from "../assets/img/szelvedok_tipusai.jpg"

function DoYouKnowWhyDamageGlass() {
    return(
        <>
            <DoYouKnowDetail
                title="A szélvédők típusai | Glassmax"
                meta="Edzett, vagy laminált, egyrészes vagy osztott, netán ívelt? Bemutatjuk a szélvédők típusait, illetve hogy milyen kiegészítő funkciókkal találkozhatunk."
                imageUrl={Image}
                header="Milyen típusú szélvédőkkel találkozhatunk?"
                text="<p>Míg edzett üveget leggyakrabban az oldalsó ablakokhoz és a hátsó szélvédőhöz használnak, addig az
                    első szélvédő laminált üvegből készül. Két üvegréteg közé egy fólia kerül, amely megakadályozza,
                    hogy a szélvédő ütközéskor, vagy baleset esetén berobbanjon. Így nem szakadnak ki belőle
                    üvegszilánkok, amelyek komoly sérüléseket okoznának a sofőrnél és az anyósülésen ülő utastársánál.
                </p>
                <p>Az első szélvédőkre vonatkozó biztonsági követelmények azért is olyan komolyabbak, mert támogatniuk
                    kell a kioldott légzsákokat ütközés esetén, ezzel is hozzájárulva az utasok védelméhez. A szélvédők
                    ragasztásához gyakran használnak rugalmas, de mégis nagy szilárdságú poliuretán ragasztókat.</p>
                <p>Egyértelmű különbségek jellemzik a vasúti járművek és a tömegközlekedésben részt vevő járművek
                    üvegezését, a laminált biztonsági üveg használata azonban általában közös pont, mivel segítségével
                    javarészt elkerülhetők a vágott sérülések.</p>
                <p>Manapság már egyre több kiegészítő funkcióval is találkozhatunk az autóüvegeken: a színezett fólia
                    például véd az erős UV-sugárzás ellen, a ráhelyezett érzékelők reagálnak az esőre, fényre és
                    páratartalomra, és ennek megfelelően szabályozzák az autó fényét és légkondicionálását. Alapvetően
                    három típusú járműüveget különböztetünk meg forma szerint:
                <ul>
                    <li>egyrészes, egyenes üveg</li>
                    <li>egyenes üveg középen kettéosztva</li>
                    <li>ívelt szélvédő</li>
                </ul>
                </p>"/>
        </>
    )
}

export default DoYouKnowWhyDamageGlass