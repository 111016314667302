import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import NextButton from "../assets/icon/next.png"
import {NavLink} from "react-router-dom"

type ElementProps = {
    odd: boolean
    imageSrc: string,
    title: string,
    text: string,
    url: string
}

function DoYouKnowMainElement(props: ElementProps) {

    return(
        <>
            <Grid container spacing={2} sx={{ paddingY: '30px' }}>
                <Grid item xs={12} md={6} sx={{ order: { sm: props.odd ? 2 : 1 } }}>
                    <Box component="div">
                        <Box component="img" src={props.imageSrc} alt="Glassmax picture" width="100%" height="auto" />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { sm: props.odd ? 1 : 2 } }}>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"

                        spacing={2}
                        sx={{ textAlign: { sm: props.odd ? 'right' : 'left' }, alignItems: { sm: props.odd ? 'flex-end' : 'flex-start' } }}>
                        <Typography variant="h4" fontWeight="bold" gutterBottom>{props.title}</Typography>
                        <Box component="div" className="sep" sx={{alignSelf: 'right'}}></Box>
                        <Typography variant="body1" gutterBottom>{props.text}</Typography>
                        <NavLink to={props.url}>
                            <img src={NextButton} alt="Next button" width="150px" />
                        </NavLink>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default DoYouKnowMainElement