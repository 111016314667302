import React from "react";
import Slider from "react-slick";
import {Box, Container, Typography} from "@mui/material";
import GalleryElement from "./GalleryElement";
import LeftIcon from "../assets/icon/left.png"
import RightIcon from "../assets/icon/right.png"
import GalleryImage1 from "../assets/img/glassmax_gallery_1.jpg"
import GalleryImage2 from "../assets/img/glassmax_takaro_serult_szelvedo.jpg"
import GalleryImage3 from "../assets/img/glassmax_auto.jpg"
import GalleryImage4 from "../assets/img/gecko_keszulek_munka_kozben.jpg"
import GalleryImage5 from "../assets/img/glassmax_matrica.jpg"
import GalleryImage6 from "../assets/img/glassmax_gallery_6.jpg"
import GalleryImage7 from "../assets/img/glassmax_gallery_7.jpg"
import GalleryImage8 from "../assets/img/glassmax_szakemberek.jpg"
import GalleryImage9 from "../assets/img/glassmax_raktar.jpg"
import GalleryImage10 from "../assets/img/glassmax_gallery_10.jpg"
import GalleryImage11 from "../assets/img/glassmax_gallery_11.jpg"
import GalleryImage12 from "../assets/img/glassmax_gallery_12.jpg"



function SampleNextArrow(props: { className?: any; style?: any; onClick?: any; }) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", backgroundImage: `url(${RightIcon})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', width: '40px', height: '40px'}}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props: { className?: any; style?: any; onClick?: any; }) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style,  display: "block", backgroundImage: `url(${LeftIcon})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', width: '40px', height: '40px' }}
            onClick={onClick}
        />
    );
}

function GalLery(){

    var settings = {
        //dots: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 600, // xs felbontás
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 9999, // minden egyéb felbontás
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
        speed: 500,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    }

    return(
        <>
            <Container sx={{ textAlign: 'center', marginY: '20px' }}>
                <Typography variant="h4" gutterBottom>GALÉRIA</Typography>
                <Box className="sep" sx={{marginX: 'auto'}}/>
                <Slider {...settings}>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage1} alt="" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage2} alt="Berobbant szélvédő glassmax takaró az autón" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage3} alt="A Glassmax autója sürgősségi  munka közben" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage4} alt="A Pro Glass Gecko készüléke munka közben" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage5} alt="Glassmax matrica egy új szélvédőn" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage6} alt="" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage7} alt="" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage8} alt="Két szakember szélvédőt cserél a Glassmax műhelyében" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage9} alt="A Glassmax szélvédő raktára" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage10} alt="" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage11} alt="" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <GalleryElement url={GalleryImage12} alt="" />
                    </Box>
                </Slider>
            </Container>
        </>
    )
}

export default GalLery