import { FormWrapper } from "./FormWrapper"
import {Container, Stack, Typography} from "@mui/material";

type VehicleData = {
    size?: string
    plateNumber: string
    vin?: string
    manufacturer: string
    type: string
    year: string
}

type VehicleFormProps = VehicleData & {
    updateFields: (fields: Partial<VehicleData>) => void
}

export function Form5({
                          size,
                          plateNumber,
                          vin,
                          manufacturer,
                          type,
                          year,
                          updateFields,
                      }: VehicleFormProps) {
    return (
        <FormWrapper title="KALKULÁCIÓ - GÉPJÁRMŰ ADATOK">
            <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}}}>
                Kérjük adja meg autója adatait:
            </Typography>
            <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}}}>
                (Az információk a forgalmi engedélyben megtalálhatóak)
            </Typography>
            <Container style={{ marginLeft: 'auto', marginRight: 'auto'}}>
                <Stack direction="column" spacing={3}>

                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}, textAlign: 'left'}}>Rendszám:</Typography>
                    <input
                        autoFocus
                        required
                        type="text"
                        value={plateNumber}
                        onChange={e => {e.target.value = e.target.value.toUpperCase(); updateFields({plateNumber: e.target.value})}}
                    />
                    {(size == null || size==='big') &&
                        <><Typography sx={{
                            typography: {sm: 'body1', xs: 'body2'},
                            fontWeight: {xs: 'bold', sm: 'bold'},
                            textAlign: 'left'
                        }}>Alvázszám:</Typography><input
                            required
                            type="text"
                            value={vin}
                            pattern="[A-HJ-NPR-Za-hj-npr-z0-9]{17}"
                            onChange={e => {
                                e.target.value = e.target.value.toUpperCase();
                                updateFields({vin: e.target.value});
                            }}/></>}
                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}, textAlign: 'left'}}>Gyártmány:</Typography>
                    <input
                        required
                        type="text"
                        value={manufacturer}
                        onChange={e => {e.target.value = e.target.value.toUpperCase(); updateFields({manufacturer: e.target.value})}}
                    />
                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}, textAlign: 'left'}}>Típus:</Typography>
                    <input
                        required
                        type="text"
                        value={type}
                        onChange={e => {e.target.value = e.target.value.toUpperCase(); updateFields({type: e.target.value})}}
                    />
                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}, textAlign: 'left'}}>Évjárat:</Typography>
                    <input
                        required
                        type="text"
                        value={year}
                        onChange={e => updateFields({year: e.target.value})}
                    />
                </Stack>
            </Container>
        </FormWrapper>
    )
}