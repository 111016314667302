import React from "react";
import Slider from "react-slick";
import {Box, Container, Typography} from "@mui/material";
import useMediaQuery from "react-responsive"
import FeedbackElement from "./FeedbackElement";
import LeftIcon from "../assets/icon/left.png"
import RightIcon from "../assets/icon/right.png"
//import './Sliders.css';

function SampleNextArrow(props: { className?: any; style?: any; onClick?: any; }) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", backgroundImage: `url(${RightIcon})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', width: '40px', height: '40px'}}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props: { className?: any; style?: any; onClick?: any; }) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style,  display: "block", backgroundImage: `url(${LeftIcon})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', width: '40px', height: '40px' }}
            onClick={onClick}
        />
    );
}

function Feedback() {

    //const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    var settings = {
        //dots: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 600, // xs felbontás
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 9999, // minden egyéb felbontás
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
        //slidesToShow: {{xs: 1, md: 3}},//isMobile?1:3,
        speed: 500,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    }

    return(
        <>
            <Container sx={{ textAlign: 'center', marginY: '20px' }}>
                <Typography variant="h4" gutterBottom>VÉLEMÉNYEK</Typography>
                <Box className="sep" sx={{marginX: 'auto'}}/>
                <Slider className="" {...settings}>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <FeedbackElement text="“Casco-ban ők a legjobbak! Magas önrész mellett is megoldották, hogy ingyen legyen a csere. A helyszínen felvették a kárt, és így elég volt csak egyszer odamennem.”" name="P. Miklós" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <FeedbackElement text="“Kaptam egy kavicsot, telefonon kértem árat és már másnap meg is történt a javítás. Minden úgy történt, ahogy ígérték.”" name="Tibor, Budapest" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <FeedbackElement text="“Sajnos, már harmadszor kellett szélvédő miatt mûhelybe vigyem az autót. Megint hozzájuk jöttem, és nem csalódtam. Azért remélem, most egy darabig nem kell jönnöm.”" name="Zozo, Dunakeszi" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <FeedbackElement text="Kijöttek Budaörsre, és az udvaron kicserélték a szélvédőt a kocsiban. Korrekt munkát végeztek, belül mindent letakartak”" name="J. Imre" />
                    </Box>
                    <Box component="div" sx={{ alignItems: 'center'}}>
                        <FeedbackElement text="“Ők voltak a negyedik cég, akit felhívtam. Nincs casco-m, de ugyanolyan segítőkészek voltak. Tanácsot adtak a szélvédő választásban is.”" name="Gabi, Budapest" />
                    </Box>
                </Slider>
            </Container>
        </>
    )
}

export default Feedback