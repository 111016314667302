import DoYouKnowDetail from "./DoYouKnowDetail";
import Image from "../assets/img/glassmax_tudta_full_2.jpg"

function DoYouKnowNeedCalibrate() {
    return(
        <>
            <DoYouKnowDetail
                title="Szélvédő kamera kalibrálás | Glassmax"
                meta="Ha a szélvédőjén kamera van, vagy autója rendelkezik valamilyen vezetéstámogató funkcióval valószínűleg csere után kalibrálni kell a szélvédőjét."
                imageUrl={Image}
                header="Honnan tudhatja, hogy szélvédőcsere után kalibrálásra van szüksége?"
                text="<p>A legtöbb új jármű rendelkezik ADAS, vagyis vezetéstámogató funkciókkal. Ahogy az autógyártás fejlődik,
                    már az olcsóbb modellekbe is egyre több kényelmi tulajdonságot építenek be. Nagy valószínűséggel
                    kalibrálásra lesz szüksége
                    a szélvédő cseréjekor, ha az autó szélvédőjén kamera van, vagy rendelkezik az alábbi funkciók
                    valamelyikével:
                <ul>
                    <li>Sávelhagyásra Figyelmeztető Rendszer</li>
                    <li>Ütközésre való figyelmeztetés</li>
                    <li>Adaptív Sebességtartó Automatika</li>
                    <li>Holttér Felderítés</li>
                    <li>Álmosság Monitor</li>
                    <li>Éjjellátó</li>
                </ul>
                </p>
                <p>A Glassmax-nál nem csak a szélvédő csere folyamán bízhat szakértemünkben. Diagnosztikai és kalibráló
                    eszközeinkkel gondoskodunk arról, hogy Ön továbbra is biztonságosan vezessen, és hogy vezetőtámogató
                    rendszerei megfelelően legyenek beállítva.</p>"/>
        </>
    )
}

export default DoYouKnowNeedCalibrate