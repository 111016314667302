import StockElement from "../../components/StockElement";


function StockTesla() {
    return(
        <>
            <StockElement
                title="Tesla szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Tesla típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="TESLA SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="MODEL S, MODEL 3, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb TESLA típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockTesla