import StockElement from "../components/StockElement";
import {Helmet} from "react-helmet";
import React from "react";


function Stock() {
    return(
        <>
            <StockElement
                title="Szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb autómárkához, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="SZÉLVÉDŐ ÉRTÉKESÍTÉS"
                body="Audi, BMW, Citroen, Dacia, Fiat, Ford, és még folytathatnánk a sort...<br/>Nálunk a legtöbb márkához, típushoz, és évjárathoz talál szélvédőt, azonnal raktárról.<br/>Óriási raktárkészletünk kínálatában nem csak a legkeresettebb gyártók modelljei, de ritkább gyártmányok is megtalálhatók." />
        </>
    )
}

export default Stock