import React from 'react'
//import ReactGA from "react-ga4"
import {Box, Container, Grid, Stack, Paper, Link, Typography, styled} from "@mui/material";
import AddressIcon from '../assets/icon/address.png'
import PhoneIcon from '../assets/icon/phone.png'
import MobileIcon from '../assets/icon/mobile.png'
import MailIcon from '../assets/icon/mail.png'
import FacebookIcon from '../assets/icon/facebook.png'
import InstagramIcon from '../assets/icon/instagram.png'
import TiktokIcon from '../assets/icon/tiktok.png'
import LinkedinIcon from '../assets/icon/linkedin.png'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//const TRACKING_ID =  "G-7SG3X5FNN2";  //"G-XXXXXXXXX";

function Footer() {

    const handleClick = () => {
        //ReactGA.initialize(TRACKING_ID)
        //setState(!state); "gtag('event', 'click', {'event_category': 'click', 'event_label': 'Call now click'})"
        /*ReactGA.event({
            category: "click",
            action: "click",
            label: "Call now click", // optional
            //value: 99, // optional, must be a number
            //nonInteraction: true, // optional, true/false
            //transport: "xhr", // optional, beacon/xhr/image
        });*/
        window.gtag('event', 'call_now_click')
    };

    return (
        <>
            <Container className="Footer" maxWidth={false} sx={{bgcolor: 'primary.main', color: '#ffffff', paddingBottom: '20px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div">
                            <Typography variant="h5" component="h5" gutterBottom sx={{fontWeight: 'bold'}}>
                                KAPCSOLAT
                            </Typography>
                            <Box component="div" className="sep"></Box>
                            <Stack spacing={2}>
                                <Stack spacing={1} direction="row">
                                    <img src={AddressIcon} width={25} alt="addres icon"/>
                                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'light', sm:'light'} }} >Cím:</Typography>
                                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'bold', sm:'bold'} }} >1154 Budapest, Gábor Áron út 112</Typography>
                                </Stack>
                                <Stack spacing={1} direction="row">
                                    <img src={PhoneIcon} width={25} alt="phone icon"/>
                                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'light', sm:'light'} }} >Telefon:</Typography>
                                    <Link href="tel:+3612203693" underline="none" color='#ffffff'><Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'bold', sm:'bold'} }} >+36 1 220 3693</Typography></Link>
                                </Stack>
                                <Stack spacing={1} direction="row">
                                    <img src={MobileIcon} width={25} alt="mobile icon"/>
                                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'light', sm:'light'} }} >Mobil:</Typography>
                                    <Link href="tel:+36209354731" underline="none" color='#ffffff'><Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'bold', sm:'bold'} }} >+36 20 935 4731</Typography></Link>
                                </Stack>
                                <Stack spacing={1} direction="row">
                                    <img src={MobileIcon} width={25} alt="mobile icon"/>
                                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'light', sm:'light'} }} >Mobil:</Typography>
                                    <Link href="tel:+36305814678" underline="none" color='#ffffff'><Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'bold', sm:'bold'} }} >+36 30 581 4678</Typography></Link>
                                </Stack>
                                <Stack spacing={1} direction="row">
                                    <img src={MailIcon} width={25} alt="mail icon"/>
                                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'light', sm:'light'} }} >E-mail:</Typography>
                                    <Typography sx={{ typography: { sm: 'body1', xs: 'body2' }, fontWeight: { xs:'bold', sm:'bold'} }} ><Link href="mailto:ertekesites@glassmax.hu" underline="none" color='#ffffff'>ertekesites@glassmax.hu</Link></Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div">
                            <Typography variant="h5" component="h5" gutterBottom sx={{fontWeight: 'bold'}}>
                                KÖVESSEN MINKET
                            </Typography>
                            <Box component="div" className="sep"></Box>
                            <Stack spacing={2}>
                                <Stack spacing={2} direction='row'>
                                    <Link target="_blank" href="https://www.facebook.com/glassmaxbudapest">
                                        <Box component="img" src={FacebookIcon} width={25} alt="Facebook icon"/>
                                    </Link>
                                    <Link target="_blank" href="#" sx={{ display: 'none'}}>
                                        <Box component="img" src={InstagramIcon} width={25} alt="Instagram icon"/>
                                    </Link>
                                    <Link target="_blank" href="#" sx={{ display: 'none'}}>
                                        <Box component="img" src={TiktokIcon} width={25} alt="Tiktok icon"/>
                                    </Link>
                                    <Link target="_blank" href="https://www.linkedin.com/company/glassmax">
                                        <Box component="img" src={LinkedinIcon} width={25} alt="Linkedin icon"/>
                                    </Link>
                                </Stack>
                                <Box>
                                    <Link target="_blank" href="aszf.pdf" underline="none" color='#ffffff'>
                                        <Typography variant="body1">
                                            ÁSZF
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box>
                                    <Link target="_blank" href="adatvedelmi.pdf" underline="none" color='#ffffff'>
                                        <Typography variant="body1">
                                            Adatvédelmi tájékoztató
                                        </Typography>
                                    </Link>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box component="div" className="sticky-button" sx={{ display: {sx: 'block', md: 'none'}, position: 'sticky' }}>
                <Link href="tel:+36305814678" onClick={(e: React.MouseEvent<HTMLElement>) => handleClick()} underline='none'><Typography variant="h5" className="sticky-text" fontWeight="bold" color="#ffffff">&gt;&gt;&gt; HÍVÁS MOST &lt;&lt;&lt;</Typography></Link>
            </Box>
        </>
    )

}

export default Footer