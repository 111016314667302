import {Box, Container, Link, Stack, Typography} from "@mui/material";
import Image from "../assets/img/glassmax_karrier.jpg";
import React from "react";
import {Helmet} from "react-helmet";

function Carrier () {
    return(
        <>
            <Helmet>
                <title>Szélvédő Javítás és Csere - Akár még ma - Glassmax</title>
                <meta name="description" content="Térjen vissza mielőbb az utakra. Kőfelverődés javítás német technológiával. Teljeskörű CASCO ügyintézés, 1 év garancia. Több mint 20 év tapasztalat." />
            </Helmet>
            <Container className="content" sx={{paddingX: {xs: '10px' }}}>
                <Stack spacing={5} flexDirection="column" justifyContent="flex-start" justifyItems="flex-start">
                    <Box textAlign="center">
                        <Box component="img" src={Image} alt="Stock picture" sx={{content: `url(${Image})`, width: { xs: '100%', md: 'auto'}, height: 'auto' }} />
                    </Box>
                    <Stack spacing={3} flexDirection="column" justifyContent="flex-start" justifyItems="flex-start" textAlign="left" sx={{marginX: '10px'}}>
                        <Box>
                            <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>KARRIER</Typography>
                            <Box className="sep"/>
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            Szívesen képeznéd magad?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Tudsz és szeretsz igényesen dolgozni?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Szereted, ha megbecsülik a munkádat?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Nálunk új fejezetet nyithatsz!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Új, piacképes szakmát tanulhatsz, vagy naprakészen tarthatod tudásod.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Kiemelkedő fizetéssel várunk egy jó csapatban!
                        </Typography>
                        <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>
                            Jelentkezz hozzánk AUTÓÜVEG BEÉPÍTŐ pozíciónkba!
                        </Typography>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Mik lesznek a feladataid?
                        </Typography>
                        <ul>
                            <li>személyautók, teherautók és kamionok üveg cseréje</li>
                            <li>szélvédők kalibrálása</li>
                            <li>kőfelverődések javítása</li>
                            <li>digitális munkalap kezelése</li>
                            <li>szélvédőcserén átesett autók rendbetétele</li>
                        </ul>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Mit várunk tőled?
                        </Typography>
                        <ul>
                            <li>B kategóriás jogosítvány</li>
                            <li>jó kommunikációs készség</li>
                            <li>ügyfélbarát hozzáállás</li>
                            <li>gyors tanulási készség</li>
                            <li>csapatban való együttműködés</li>
                        </ul>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Előnyt jelent,
                        </Typography>
                        <ul>
                            <li>ha van karosszéria lakatos/autóüveg beépítő/autószerelő végzettséged és/vagy munkatapasztalatod</li>
                            <li>szélvédős tapasztalatod</li>
                            <li>idegennyelv ismereted (angol vagy német külföldi partner cégek miatt)</li>
                        </ul>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Amit kínálunk:
                        </Typography>

                    <ul>
                        <li>modern munkakörnyezet, a szaknak megfelelő prémium szerszámok</li>
                        <li>sokéves stabil háttérrel rendelkező cég</li>
                        <li>fiatalos csapat</li>
                        <li>szakmai belüli továbbképzések, új technikák elsajátítása (pl. kamera kalibráció TEXA rendszerrel, kőfelverődés gép betanítása)</li>
                        <li>külföldi tréninglehetőség</li>
                        <li>szabad esték és hétvégék</li>
                        <li>pluszmunka lehetőség</li>
                        <li>a szakmán belül kiemelkedő bérezés</li>
                    </ul>

                    <Typography variant="body1" fontWeight="bold" gutterBottom>
                        Rólunk
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            A Glass Max Hungary Kft immáron több mint éve 20 éve meghatározó alakja a
                            magyar autó szélvédő piacnak. Saját szerelőműhellyel és szélvédő raktárral
                            rendelkezik, mely több mint 20.000 db terméknek biztosít helyet. Külföldi
                            beszállítókkal és gyárakkal való együttműködése, illetve ezek befektetései
                            határozottan versenyképessé teszik az itthoni piacon. Kiváló kapcsolat az itthoni
                            biztosító cégekkel és több neves autó szalonnal is.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Felkeltettük érdeklődésed?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Fényképes önéletrajzod az <Link href="mailto:adamdaadou@glassmax.hu">adamdaadou@glassmax.hu</Link> e-mail címre várjuk.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Örülünk, hogy megismerhetünk!
                        </Typography>
                        <p>&nbsp;</p>
                   </Stack>
                </Stack>
            </Container>
        </>
    )
}

export default Carrier