import React, { ReactNode } from "react"
import Container from "@mui/material/Container";
import {Box, Link, Stack, Typography} from "@mui/material";
import Image from "../../assets/img/glassmax_kalkulator.jpg";
import ImageMobile from "../../assets/img/glassmax_kalkulator_mobile.jpg";

type FormWrapperProps = {
    title: string
    children: ReactNode
}

export function FormWrapper({ title, children }: FormWrapperProps) {
    return (
        <>
            <Container className="content" maxWidth={false} sx={{paddingX: {xs: 0 }}}>
                <Stack spacing={5} flexDirection="column" justifyContent="center" justifyItems="center">
                    <Box textAlign="center">
                        <Box component="img" src={Image} alt="Stock picture" sx={{content: {xs: `url(${ImageMobile})`, md: `url(${Image})`}, width: '100%', height: 'auto' }} />
                    </Box>
                    <Stack spacing={3} flexDirection="column" justifyContent="center" justifyItems="center" textAlign="center">
                        <Box>
                            <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>{title}</Typography>
                            <Box className="sep" sx={{marginX: 'auto', alignSelf: 'right'}}/>
                        </Box>
                        {children}
                    </Stack>
                </Stack>
            </Container>
        </>
    )
}