import {Box, Container, Link, Stack, Typography} from "@mui/material";
import CalendarIcon from "../assets/icon/price_date.png"
import BackgroundImage from "../assets/img/gradient.png"
function AppointmentLine() {
    return(
        <>
            <Container maxWidth={false} sx={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', paddingY: '20px', marginTop: '-10px', marginBottom: '10px'}}>
                <Box sx={{display: 'flex', flexDirection: "row"}}>
                    <Box sx={{paddingX: '10px', display: {xs: 'none', md: 'block'}}}>
                        <img src={CalendarIcon} alt="Calendar icon"/>
                    </Box>
                    <Stack>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2' } }} color="#ffffff">Árak, időpont egyeztetés:</Typography>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2' } }} color="#ffffff">
                            <Link href="tel:+3612203693" color="#ffffff" underline="none">+36 1 220 3693 </Link>
                            vagy
                            <Link href="tel:+36305814678" color="#ffffff" underline="none"> +36 30 581 4678 </Link>
                            vagy
                            <Link href="tel:+36209354731" color="#ffffff" underline="none"> +36 20 935 4731</Link>
                        </Typography>
                    </Stack>
                </Box>
            </Container>
        </>
    )
}

export default AppointmentLine