import React from "react";
//import ReactGA from "react-ga4";
import {Box, Container, Link, Stack, Typography} from "@mui/material"
import Image from "../assets/img/szelvedok_raktaron.jpg"
import ImageMobile from "../assets/img/szelvedok_raktaron_mobile.jpg";
import CallMeIcon from "../assets/icon/call_me.png"
import {Helmet} from "react-helmet";

type ElementProps = {
    title: string,
    meta: string,
    header: string,
    body: string
}

//const TRACKING_ID =  "G-7SG3X5FNN2";  //"G-XXXXXXXXX";

function StockElement({title, meta, header, body}: ElementProps) {

    const handleClick = () => {
        /*ReactGA.initialize(TRACKING_ID)
        ReactGA.event({
            category: "click",
            action: "click",
            label: "Call me click", // optional
            //value: 99, // optional, must be a number
            //nonInteraction: true, // optional, true/false
            //transport: "xhr", // optional, beacon/xhr/image
        });
        //setState(!state); gtag('event', 'click', {'event_category': 'click', 'event_label': 'Call me click'});*/
        window.gtag('event', 'call_me_click');
    };

    return(
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={meta} />
            </Helmet>
            <Container className="content" maxWidth={false} sx={{paddingX: {xs: 0 }}}>
                <Stack spacing={5} flexDirection="column" justifyContent="center" justifyItems="center">
                    <Box textAlign="center">
                        <Box component="img" src={Image} alt="Stock picture" sx={{content: {xs: `url(${ImageMobile})`, md: `url(${Image})`}, width: '100%', height: 'auto' }} />
                    </Box>
                    <Stack spacing={3} flexDirection="column" justifyContent="center" justifyItems="center" textAlign="center">
                        <Box>
                            <Typography component="h1" sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>{header}</Typography>
                            <Box className="sep" sx={{marginX: 'auto', alignSelf: 'right'}}/>
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            <div dangerouslySetInnerHTML={{ __html: body }}/>
                        </Typography>
                        <Link href="tel:+36305814678" onClick={(e: React.MouseEvent<HTMLElement>) => handleClick()}>
                            <Box component="img" src={CallMeIcon} alt="Call me icon" className="call-me" sx={{width: {xs: '280px', md: '550px'}}} />
                        </Link>
                        <Typography sx={{ marginBottom: '10px', typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}  }} gutterBottom>Az ajánlat pár percet vesz igénybe: beazonosítjuk milyen cikkszámú szélvédőre van szüksége és árat adunk.</Typography>
                    </Stack>
                </Stack>
            </Container>
        </>
    )
}

export default StockElement