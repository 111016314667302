import {Box, Paper, Typography} from "@mui/material";

type FeedbackProps = {
    text: string,
    name: string
}
function FeedbackElement(prop: FeedbackProps) {
    return(
        <Paper sx={{textAlign: 'center', backgroundColor: '#f1f1f1;', width: {xs: '300px', md: '350px'}, marginX: 'auto', padding: '5px'}}>
            <Box>
                <Typography sx={{typography: 'body1', paddingY: '20px'}} >{prop.text}</Typography>
            </Box>
            <Box>
                <Typography variant="body1" sx={{paddingY: '20px'}}>{prop.name}</Typography>
            </Box>
        </Paper>
    )
}

export default FeedbackElement