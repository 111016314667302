import StockElement from "../../components/StockElement";


function StockCitroen() {
    return(
        <>
            <StockElement
                title="CITROËN szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb CITROËN típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="CITROËN SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="BERLINGO, C1, C2, C3, C3 PICASSO, C4, C4 PICASSO, C5, C6, C8, JUMPER,
                    JUMPY, XSARA, XSARA PICASSO, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb CITROËN típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockCitroen