import {useNavigate, useParams} from "react-router-dom";
import {Button, Container, Link, Stack, Typography} from "@mui/material";
import {apiUrl} from "../config/SiteConfig";
import {useEffect, useState} from "react";
import { DayPicker } from 'react-day-picker';
import classNames from "react-day-picker/style.module.css";
import { hu } from "date-fns/locale";

const Booking = () => {
    const {id} = useParams()
    const [status, setStatus] = useState()
    const [availableDates, setAvailableDates] = useState([])
    const [availableTimes, setAvailableTimes] = useState([])
    const [times, setTimes] = useState<string[] | null>([])
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedTime, setSelectedTime] = useState<string | null>(null)

    const navigate = useNavigate();

    //const dates = ["2024-09-25", "2024-09-26", "2024-09-27", "2024-09-30", "2024-10-01","2024-10-02", "2024-10-03", "2024-10-04", "2024-10-07", "2024-10-08"]
    //const times = [{date: "2024-09-25", times: ["12:00", "15:00"]}, {date: "2024-09-26", times: ["09:00", "10:00", "12:00"]}, {date: "2024-09-27", times: ["10:00", "12:00", "13:00", "15:00"] }
   //     , "2024-09-30", "2024-10-01","2024-10-02", "2024-10-03", "2024-10-04", "2024-10-07", "2024-10-08"]

    type Schedule = { date: string; times: string[] };

    // 1. Függvény: Kiszedi a date mezőket
    function extractDates(scheduleList: Schedule[]): string[] {
        return scheduleList.map(item => item.date);
    }

    // 2. Függvény: Szűr a megadott date-re, és visszaadja a time-listát
    function filterTimesByDate(scheduleList: Schedule[], targetDate: string): string[] {
        const foundItem = scheduleList.find(item => item.date === targetDate);
        return foundItem ? foundItem.times : [];
    }

    function getDisabledDates(dateStrings: string[]): Date[] {
        // Átalakítjuk a stringeket Date típusra
        const dates = dateStrings.map(dateStr => new Date(dateStr));

        const missingDates: Date[] = [];

        // Kezdő dátum (első elem) és záró dátum (utolsó elem)
        const startDate = dates[0];
        const endDate = dates[dates.length - 1];

        // Végigmegyünk a kezdő és záró dátumok közötti napokon
        let currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + 1); // Kezdjük az első utáni nappal

        while (currentDate < endDate) {
            // Ha az aktuális dátum nincs a bemeneti listában, hozzáadjuk a hiányzó dátumokhoz
            if (!dates.some(date => date.getTime() === currentDate.getTime())) {
                missingDates.push(new Date(currentDate)); // Új példányt adunk hozzá
            }

            // Lépünk a következő napra
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return missingDates;
    }

    function getTimes(){

    }

    const timeClick = (time: string) => {
        setSelectedTime(time)
    }

    const book = () => {
        if(selectedDate != null && selectedTime != null){
            const year = selectedDate.getFullYear();
            const month = String(selectedDate?.getMonth() + 1).padStart(2, '0'); // Hónapok 0-tól indulnak
            const day = String(selectedDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;
            const postData = new FormData();
            postData.append('id', id ? id : '');
            postData.append('date', formattedDate);
            postData.append('time', selectedTime);
            console.log('book')
            fetch(apiUrl+'/curiosity/booking', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({'id': id ? id : '', 'date': formattedDate,'time': selectedTime}),
            })
                .then((response) => response.json())
                .then((data) => {
                    navigate('/booking-thankyou.html')
                    // Handle the response data
                })
                .catch((error) => {
                    alert("Hiba a küldés során. Próbálja meg később!")
                });
        }

    }
    /*function getTimesByDate(date: Date): string[] {
        const dateStr = date.toISOString().split('T')[0];
        // Keresd meg a megfelelő elemet a data tömbben
        const schedule = availableDates.find((item: Schedule) => item.date === dateStr);
        // Hívd meg a setTimes függvényt, ha van ilyen dátum
        setTimes(schedule ? schedule.times : [])
    }*/

    useEffect(() => {
        // Fetch hívás csak az oldal betöltésekor fut le
        fetch(apiUrl + "/curiosity/" + id, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data: ", data);
                setStatus(data.status);
                setAvailableTimes(data.availableDates);
                //setAvailableDates(extractDates(data.availableDates));
                setAvailableDates(data.availableDates.map((item: Schedule) => item.date));
                //setSelectedDate(new Date(availableDates[0]))
            })
            .catch((error) => {
                console.error("Hiba a küldés során. Próbálja meg később!");
            });
    }, [id, navigate]);

    useEffect(() => {
        if(selectedDate != null){
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Hónapok 0-tól indulnak
            const day = String(selectedDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;
            console.log('Formatted date2: ', formattedDate);
            console.log('Selected date2: ', selectedDate?.toISOString().split('T')[0]);
            const x = filterTimesByDate(availableTimes, formattedDate);
            console.log('times: ', x)
            setTimes(x);
            setSelectedTime(null);
        }
    }, [selectedDate]);

    return(
        <>
            <Container className="content" sx={{paddingX: {xs: 0}}}>
                <h1>Időpont foglalás</h1>
                {status && status === 'NO_PROPOSAL' &&
                    <h2>Nincs ilyen ajánlat</h2>
                }
                {status && status === 'EXPIRED_PROPOSAL' &&
                    <>
                        <h2>Az ajánlat érvényessége lejárt</h2>
                        <Typography>Hívja a <Link href="tel:+36305814678" underline="none">+36 30 581 4678</Link> számot.</Typography>
                    </>
                }
                {status && status === 'BOOKED' &&
                    <>
                        <h2>időpont foglalás már megtörtént</h2>
                        <Typography>Módosításhoz hívja a <Link href="tel:+36305814678" underline="none">+36 30 581 4678</Link> számot.</Typography>
                    </>
                }
                {status && status === 'AVAILABLE' &&
                    <>

                    <Stack direction="row" justifyContent="center">
                        <div><DayPicker
                            classNames={classNames}
                            locale={hu}
                            mode="single"
                            disabled={[{ before: new Date(availableDates[0]) }, { after: new Date(availableDates[availableDates.length-1]) }, ...getDisabledDates(availableDates)]}
                            selected={selectedDate ?? new Date(availableDates[0])}
                            startMonth={new Date(availableDates[0])} endMonth={new Date(availableDates[availableDates.length-1])}
                            onSelect={(date) => setSelectedDate(date ?? null)}
                        />
                        </div>
                        <div>
                            <Stack direction="column">
                                {
                                    times?.map(((time, i) => {
                                            console.log("Time: ", time);
                                            // Return the element. Also pass key
                                        return (<Button onClick={() => timeClick(time)}>{time}</Button> )
                                        })
                                    )
                                }
                            </Stack>
                        </div>
                    </Stack>
                        { selectedDate && selectedTime &&
                            <div>A választott időpont: {selectedDate?.toLocaleDateString()} {selectedTime}</div>
                        }
                        {
                            <Button disabled={!selectedTime} onClick={() => book()}>Időpontot foglalok</Button>
                        }
                    </>}
            </Container>
        </>
    )
}

export default Booking