import StockElement from "../../components/StockElement";


function StockVolkswagen() {
    return(
        <>
            <StockElement
                title="Volkswagen szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Volkswagen típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="VOLKSWAGEN SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="ARTEON, BEETLE, CADDY, CRAFTER, GOLF, JETTA, PASSAT, PHAETON, POLO,
                    SHARAN, T-CROSS, T-ROC, TIGUAN, TOUREG, TOURAN, TRANSPORTER, T4,
                    T5, UP, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb VOLKSWAGEN típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockVolkswagen