import DoYouKnowDetail from "./DoYouKnowDetail";
import Image from "../assets/img/szelvedo_javitas_gecko_keszulek.jpg"
import ImageBefore from "../assets/img/kavicsfelverodes_elotte.png"
import ImageAfter from "../assets/img/kavicsfelverodes_utana.png"
import {Box, Container, Link, Stack, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import BackIcon from "../assets/icon/back.png";
import React from "react";
import {Helmet} from "react-helmet";
import CallMeIcon from "../assets/icon/call_me.png";
import ReactGA from "react-ga4";

const TRACKING_ID =  "G-7SG3X5FNN2";

function DoYouKnowWindscreenFix() {

    const handleClick = () => {
        ReactGA.initialize(TRACKING_ID)
        ReactGA.event("call_me_click");
        //setState(!state); "gtag('event', 'click', {'event_category': 'click', 'event_label': 'Call me click'});"
    };
    return(
        <>
            <Helmet>
                <title>Minden a szélvédő javításról | Glassmax</title>
                <meta name="description" content="Szélvédő javítás könnyen, szakszerűen, a vezető német technológiának köszönhetően. Időpontfoglalás után mindössze 30 perc és szélvédője újra hibátlan lesz." />
            </Helmet>
            <Container className="content">
                <Stack>
                    <Box component="img" src={Image} alt="szelvedo javitas gecko keszulekkel" sx={{ marginY: '20px'}}></Box>
                    <Typography component="h1" variant="h4" fontWeight="bold" gutterBottom>A szélvédő javítás célja és menete</Typography>
                    <Box component="div" className="sep"/>
                    <Typography component="p" variant="body1" gutterBottom>
                        A kavicsfelverődés és a jégeső okozta kár nem csupán optikai hiba!
                    </Typography>
                    <ul>
                        <li>zavarhatja a látást, főleg sötétben, nehéz fényviszonyok között</li>
                        <li>bármikor repedéssé válhat, ami már jóval költségesebb beavatkozást igényel</li>
                        <li>sérül a szélvédő szilárdsága</li>
                    </ul>
                    <Typography component="p" variant="body1" gutterBottom>
                        Ezért a műszaki vizsgán, illetve közúti ellenőrzés során is odafigyelnek a szélvédő hibáira.
                    </Typography>
                    <Typography component="h2" variant="h5" fontWeight="bold" sx={{marginY: "1rem"}} gutterBottom>
                        Mikor lehetséges a szélvédő javítása?
                    </Typography>
                    <Typography component="p" variant="body1" gutterBottom>
                        Általában az alábbi feltételek esetén van mód a szélvédő megmentésére:
                    </Typography>
                    <ul>
                        <li>a sérülés nem nagyobb egy 100 forintos érménél</li>
                        <li>az ablakkeret szélétől mért távolsága legalább 5-6 cm</li>
                        <li>nem a vezető látómezőjébe esik</li>
                        <li>nincs belőle több darab a szélvédőn</li>
                        <li>még nem vált repedéssé</li>
                    </ul>
                    <Typography component="h3" variant="h5" fontWeight="bold" sx={{marginY: "1rem"}} gutterBottom>
                        Hogyan történik a szélvédő javítása?
                    </Typography>
                    <ul>
                        <li>tapasztalt kollégáink 30 perc alatt megjavítják sérült szélvédőjét</li>
                        <li>a <a href="https://proglass.de/en/products/gecko/" target="_blank">német ProGlass GECKO</a> készülékével, piacvezető technológiával tüntetjük el a kőfelverődés,
                            karcolás, jégkár nyomát</li>
                        <li>a sérült területet speciális gyantával töltjük fel, melytől az üveg újra sima és szilárd szerkezetű lesz</li>
                        <li>Casco biztosítás esetén a kárügyintézést teljeskörűen elvégezzük, ilyenkor a javítás a legtöbb esetben ingyenes</li>
                        <li>amíg kényelmes ügyfélterünkben iszik egy kávét, mi közben újra hibátlanná varázsoljuk szélvédőjét</li>
                    </ul>
                    <Stack direction="column" spacing={2} sx={{marginY: '2rem', textAlign: 'center'}}>
                        <Typography component="h3" variant="h5" fontWeight="bold" gutterBottom>
                            GYORS – SZAKSZERŰ - KÉNYELMES
                        </Typography>
                        <Typography component="p" variant="body1" gutterBottom>
                            Ajánlatért és időpontért hívjon minket, hogy gyorsan egyeztethessük a részleteket:
                        </Typography>
                        <Link href="tel:+36305814678" onClick={(e: React.MouseEvent<HTMLElement>) => handleClick()}>
                            <Box component="img" src={CallMeIcon} alt="Call me icon" className="call-me" sx={{width: {xs: '280px', md: '550px'}}} />
                        </Link>
                        <Typography component="p" variant="body1" gutterBottom>
                            Szélvédője újra sértetlen lehet akár már holnap!
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <Box component="img" src={ImageBefore} alt="kavicsfelverodes javitas elott" width="50%"/>
                            <Box component="img" src={ImageAfter} alt="kavicsfelverodes javitas utan" width="50%"/>
                        </Stack>
                    </Stack>
                    <NavLink to="/tudta.html"><Box component="img" src={BackIcon} width="150px"/></NavLink>
                </Stack>
            </Container>
        </>
    )
}

export default DoYouKnowWindscreenFix