import DoYouKnowDetail from "./DoYouKnowDetail";
import Image from "../assets/img/glassmax_tudta_full_2.jpg"

function DoYouKnowWhyDamageGlass() {
    return(
        <>
            <DoYouKnowDetail
                title="Sérült autóüveg | Glassmax"
                meta="Az elhanyagolt szélvédő sérülés később nagyobb bajt is okozhat. Gondoskodjon időben a megoldásról, ne engedje, hogy a hiba repedéssé váljon."
                imageUrl={Image}
                header="Miért fontos foglalkozni a sérült autóüveggel?"
                text="<p>A szélvédő nem csak a szél és az időjárás viszontagságai ellen nyújt védelmet. A modern autók
                    könnyűszerkezetes felépítése egyre inkább magába foglalja a szélvédőt teljes konstrukcióban. A
                    szélvédő így a jármű szerkezetének alapvető elemévé válik, és a jármű karosszériájának szerves
                    részét képezi.
                    Azonban sajnos egy kis kavics is elég, hogy ez a védelmi rendszer sérüljön. A kőfelverődés okozta
                    kár nem csupán optikai hiba. Súlyosan zavarhatja a látást, különösen éjszaka, amikor, az esetleges
                    háttérvilágítás fényét megtöri a szélvédőn lévő sérülés.</p>
                <p>Rosszabb esetben repedés keletkezhet belőle, ami miatt az egész szélvédőt ki kell cserélni. Ezért a
                    műszaki vizsgaállomásokon is odafigyelnek az ilyen kis károkra. A lízing járműveket például
                    általában nem is veszik vissza javítatlan kavicsfelverődés okozta sérülésekkel, mivel azok
                    csökkentik az autó értékét eladáskor. Tehát mindenképpen érdemes a kőfelverődések javítását
                    letudni.</p>
                <p>Az oldalsó ablakok általában úgynevezett edzett biztonsági üvegből készülnek. (Ellentétben a
                    szélvédővel, ami laminált üvegből készül). Sérülésük oka leggyakrabban baleset, betörés, vagy
                    vandalizmus. Az oldalsó üvegek sérülve apró morzsákra törnek, ezért javításuk nem, kizárólag
                    cseréjük lehetséges.
                    A hátsó üveg sérülésének oka szintén baleset, vagy vandalizmus. Mivel ezek ugyanúgy berobbanhatnak
                    cseréjük minden ilyen esetben szükséges.</p>
                <p>A panoráma vagy napfénytetők esetében is olyan típusú károk keletkeznek általában, amelyek miatt
                    azonnali csere szükséges.Ha esetleg repedéseket vagy beázást észlel a napfény- vagy panorámatetőn,
                    hívjon bennünket. Felmérjük a kárt és azonnal orvosoljuk a problémát.</p>"/>
        </>
    )
}

export default DoYouKnowWhyDamageGlass