import StockElement from "../../components/StockElement";


function StockRenault() {
    return(
        <>
            <StockElement
                title="Renault szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Renault típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="RENAULT SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="CAPTUR, LIO, ESPACE, EXPRESS, FLUENCE, KANGOO, KOLEOS, LAGUNA,
                    MEGANE, MEGANE SCENIC, THALIA, TWINGO, ZOE, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb RENAULT típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockRenault