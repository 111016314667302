import {Box, Paper, Typography} from "@mui/material";
import backgroundImageTitleMobile from "../assets/img/gradient_mobile.png";
import backgroundImageTitle from "../assets/img/gradient.png";

type FeedbackProps = {
    url: string,
    alt: string
}
function GalleryElement({url, alt}: FeedbackProps) {
    return(
        <Paper sx={{ textAlign: 'center', backgroundColor: '#f1f1f1;', width: '330px',marginX: 'auto', padding: '5px'}}>
            <Box component="img" src={url} alt={alt} width="330px" height="330px">
            </Box>
        </Paper>
    )
}

export default GalleryElement