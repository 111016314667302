import {Box, Container, Stack, Typography} from "@mui/material";
import React from "react";
import backgroundImage from "../assets/img/glassmax_certificate.jpg";
import Certificate1 from "../assets/img/certificate_1.png"
import Certificate2 from "../assets/img/certificate_2.png"

function Certificate() {
    return(
        <>
            <Container maxWidth={false} sx={{backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', paddingY: '40px', marginY: '20px', textAlign: 'center'}}>
                <Typography variant="h4" color="#ffffff" gutterBottom>TANÚSÍTVÁNYOK</Typography>
                <Box className="sep" sx={{marginX: 'auto'}}/>
                <Typography variant="body1" color="#ffffff" gutterBottom>Az Ön biztonságát a legmagasabb minőség garantálja. <br/>
                    A Glass Max Hungary Kft-re a DIN ISO 9001:2015 szabvány szigorú követelményei vonatkoznak,
                    melyeknek minden szempontból meg kell felelnünk a tevékenységünk során. Mûhelyünkben csak OEM minőségû autóüvegeket
                    és segédanyagokat használunk.</Typography>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}
                >
                    <img src={Certificate1} alt=""/>
                    <img src={Certificate2} alt=""/>
                </Stack>
            </Container>
        </>
    )
}

export default Certificate