import {Box, Button, Container, Link, Stack, Typography} from "@mui/material";
import Image from "../../assets/img/glassmax_kalkulator_logo.png";
import ImageMobile from "../../assets/img/glassmax_kalkulator_logo_mobile.png";
import React from "react";

function BookingThankYou() {

    return (
        <>
            <Container className="content" maxWidth="lg" sx={{paddingX: {xs: 0 }}}>
                <Stack spacing={5} flexDirection="column" justifyContent="center" justifyItems="center">
                    <Box textAlign="center">
                        <Box component="img" src={Image} alt="Stock picture" sx={{content: {xs: `url(${ImageMobile})`, md: `url(${Image})`}, height: 'auto', width: '70%' }} />
                    </Box>
                    <Stack spacing={3} flexDirection="column" justifyContent="center" justifyItems="center" textAlign="center">
                        <Box>
                            <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>KÖSZÖNJÜK</Typography>
                            <Box className="sep" sx={{marginX: 'auto', alignSelf: 'right'}}/>
                        </Box>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>A megadott adatok alapján, az időpontot lefoglaltuk. A foglalásról emailt küldünk.
                            Kérjük, hogy a biztonság kedvéért ellenőrizze levelezése spam mappáját is!
                        </Typography>
                    </Stack>
                    <Box component="div" sx={{marginY: '1rem'}}/>
                </Stack>
            </Container>
        </>
    )
}

export default BookingThankYou