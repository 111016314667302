import {Box, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom"

type AboutProp = {
    img: string,
    title: string,
    text: string,
    linkUrl?: string,
    linkText?: string
}
function AboutItem(prop: AboutProp) {
    return(
        <>
            <Stack>
                <Box><img src={prop.img} alt="icon" width='100'/></Box>

                <Typography variant="h5">{prop.title}</Typography>
                <Typography variant="body1">{prop.text}</Typography>
                {prop.linkUrl != null ? <Link to={prop.linkUrl}><Typography variant="body1" fontWeight="bold" color="#000000">{prop.linkText}</Typography></Link>: null}
            </Stack>
        </>
    )
}

export default AboutItem