import StockElement from "../../components/StockElement";


function StockHyundai() {
    return(
        <>
            <StockElement
                title="Hyundai szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Hyundai típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="HYUNDAI SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="H1, H100, H350, ELANTRA, I20, I30, I40, IONIQ, IX 35, IX 55, KONA, SANTA FE,
                    TUCSON, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb HYUNDAI típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockHyundai