import DoYouKnowDetail from "./DoYouKnowDetail";
import Image from "../assets/img/szelvedo_regisztracios_matrica.jpg"

function DoYouKnowImportant() {
    return(
        <>
            <DoYouKnowDetail
                title="Autópálya és regisztrációs matricák | Glassmax"
                meta="A szélvédőjén lévő regisztrációs és autópálya matricákat igyekszünk az új szélvédőre sérülésmentesen átragasztani. Miért fontos ez?"
                imageUrl={Image}
                header="Szélvédőcsere? Erre kiemelt figyelmet kell fordítani!"
                text="<p>A szélvédőn lévő autópálya- és regisztrációs matricák levételekor sérülhetnek.
                    Az ügyfél felelősségére a regisztrációs matricát, amennyiben lehetséges, megfelelő minőségben
                    átragasztjuk az új szélvédőre vagy, kérésére, egy fólián átadjuk.
                </p>
                <p>A regisztrációs matrica sérülésmentes átragasztása, vagy annak pótlása kiemelten fontos.
                </p>
                <p>A közlekedési okmányokat szabályozó rendelet értelmében a regisztrációs matrica a rendszámtáblához
                    tartozik, így a matrica sérülése olyan, mintha a rendszámtábla lenne sérült.
                    A rendszámtáblához tartozó regisztrációs matrica személygépkocsi járműnyilvántartásba vételét
                    és a hatósági jelzés valódiságát igazolja, így anélkül a rendszámtábla érvénytelen,
                    sérülése esetén a pótlása a közlekedési igazgatási hatóság engedélyével lehetséges és egyben
                    szükséges is. Ha a regisztrációs matrica elveszett, vagy olyan mértékben sérült, hogy az
                    azonosítók rajta nem olvashatóak, akkor a járművet át kell rendszámozni.
                </p>
                <p>Ha pótlásra van szükség, segít ha a regisztrációs matricáról egy jó minőségű fényképfelvétellel
                    rendelkezünk. A matrica egyébként az eredetiség igazolása mellett azt bizonyítja, hogy a
                    járművön a rendszámot nem cserélték át, illetve ha esetleg hiányzik, akkor is be lehet azonosítani.
                </p>
                <p>Műszaki vizsga előtt győződjünk meg róla, hogy a matrica rendben van, így nem érhet bennünket meglepetés!
                </p>"/>
        </>
    )
}

export default DoYouKnowImportant