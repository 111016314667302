import { FormWrapper } from "./FormWrapper"
import {Container, Stack, Typography, useMediaQuery} from "@mui/material";
import CustomRadio from "../../components/CustomRadio";
import ImageMobile1 from "../../assets/img/glassmax_kalkulator_100_kisebb_mobile.jpg";
import Image1 from "../../assets/img/glassmax_kalkulator_100_kisebb.jpg";
import ImageMobile2 from "../../assets/img/glassmax_kalkulator_100_nagyobb_mobile.jpg";
import Image2 from "../../assets/img/glassmax_kalkulator_100_nagyobb.jpg";
import {useState} from "react";

type SizeData = {
    size?: string
    picture?: File | undefined
}

type SizeFormProps = SizeData & {
    updateFields: (fields: Partial<SizeData>) => void
}

export function Form2({
                          size,
                          picture,
                          updateFields,
                      }: SizeFormProps) {

    const [selectedValue, setSelectedValue] = useState(size);
    const [file, setFile] = useState<File | null>(null);

    const isSmallScreen = useMediaQuery("(max-width:600px)");

    const [showDetails, setShowDetails] = useState(size==='big');

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            updateFields({picture: e.target.files[0]})
        }
    };

    const handleRadioChange = (value: string) => {
        setSelectedValue(value);
        updateFields({size: value})
        if(value === 'small'){
            setShowDetails(true)
        }
        else {
            setShowDetails(false)
        }

    };


    return (
        <FormWrapper title="KALKULÁCIÓ - SÉRÜLÉS NAGYSÁGA">
            <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}}}>
                Száz forintosnál nagyobb vagy kisebb a sérülés?
            </Typography>
            <Stack direction={{xs: 'column', md: 'row'}} spacing={5} alignItems="center" justifyContent="center">
                <CustomRadio
                    autoFocus
                    name="size"
                    value="big"
                    imageSrc={isSmallScreen ? ImageMobile1 : Image1}
                    checked={selectedValue === "small"}
                    onChange={() => handleRadioChange("small")}
                />
                <CustomRadio
                    name="size"
                    value="small"
                    imageSrc={isSmallScreen ? ImageMobile2 : Image2}
                    checked={selectedValue === "big"}
                    onChange={() => handleRadioChange("big")}
                />
            </Stack>
            { showDetails &&
                <Container style={{ marginLeft: 'auto', marginRight: 'auto'}}>
                    <Stack direction="column" spacing={3}>

                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}, textAlign: 'left'}}>Fénykép a sérülésről:</Typography>
                        <input
                            autoFocus
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </Stack>
                </Container>
            }
        </FormWrapper>
    )
}