import StockElement from "../../components/StockElement";


function StockFord() {
    return(
        <>
            <StockElement
                title="Ford szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Ford típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="FORD SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="B-MAX, FIESTA, FOCUS, FOCUS C-MAX, GALAXY, KA, MONDEO, MUSTANG,
                    RANGER, S-MAX, TRANSIT, TRANSIT CUSTOM, TRANSIT COURIER, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb FORD típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockFord