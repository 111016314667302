import {Box} from "@mui/material";
import "./CustomRadio.css";
import {useState} from "react";

type RadioProp = {
    name: string
    value: string
    imageSrc: string
    checked: boolean
    onChange: () => void
    autoFocus?: boolean
}

function CustomRadio({name, value, imageSrc, checked, onChange, autoFocus}: RadioProp) {

    return(
        <label className="custom-radio">
            <input type="radio"
                   autoFocus={autoFocus}
                   required
                   name={name}
                   value={value}
                   checked={checked}
                   onChange={onChange}
                   className="custom-radio-input"/>
            <Box component="img" src={imageSrc} className="custom-radio-img"/>
            <span className={`custom-radio-check ${checked ? "checked" : ""}`}></span>
        </label>
    )
}

export default CustomRadio