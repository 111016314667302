import {Box, Container, Grid, Link, Stack, Typography} from "@mui/material";
import BackgroundImage from "../assets/img/gradient.png";
import VideoImage from "../assets/img/glassmax_repair.jpg"
import GeckoImage from "../assets/img/glassmax_gecko_budge.png"
import GeckoImageMobile from "../assets/img/glassmax_gecko_budge_mobile.png"
import PlayIcon from "../assets/icon/play.png";
import React from "react";
//import useMediaQuery from "react-responsive";

function Repair(){
    return(
        <>
            <Container maxWidth={false} sx={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', paddingY: '20px', textAlign: 'center' }}>
                <Typography variant="h5" color="#ffffff">JAVÍTÁS VAGY CSERE</Typography>
            </Container>
            <Grid container spacing={0} sx={{backgroundColor: "#000025", position: 'relative'}}>
                <Grid item xs={12} sm={6} sx={{ backgroundImage: `url(${VideoImage})`, backgroundPosition: 'right top', backgroundRepeat: 'no-repeat',  height: '500px' }}>
                    <Box component="img" sx={{ content: {xs: `url(${GeckoImageMobile})`, md: `url(${GeckoImage})`}, position: 'absolute', left: 0, top: { sx: 0, md: 'auto'}, bottom: {md: 0} }} alt="Gecko image"/>
                    <Link href="https://www.youtube.com/watch?v=IQSknt4AqLw" target="_blank"><Box component="img" src={PlayIcon} className="play-icon" alt="Play icon" sx={{position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/></Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{height: {xs: 'auto', md:'500px'} }}
                    >
                        <Typography variant="body1" color="#ffffff" fontWeight="bold" sx={{padding: "15px"}}>Amikor csak lehet, igyekszünk megmenteni a szélvédőjét.</Typography>
                        <Typography variant="body1" color="#ffffff" sx={{padding: "15px"}}>A <Box component="span" sx={{fontWeight: 'bold'}}>GECKO</Box> a ProGlass új generációs készüléke,
                            amellyel a piacon egyedülálló precizitással tüntetjük el szélvédőjéről a
                            kőfelverődés nyomát,
                            megakadályozva annak továbbterjedését.
                        </Typography>
                        <Box component="div" className="sep" sx={{marginLeft: "15px"}}></Box>
                        <Typography variant="body1" color="#ffffff" sx={{padding: "15px"}}>A sérült terület megtisztítjuk, majd az üveggel majdnem azonos optikai
                            tulajdonságokkal rendelkező speciális gyantával feltöltjük.
                            A javított terület sima és szerkezetileg szilárd lesz.
                            A GECKO a legtöbb mintázatú kőfelverődést egyformán jól javítja.
                        </Typography>
                        <Box sx={{backgroundColor: "#ee312f", padding: "15px"}}>
                            <Link href="https://www.youtube.com/watch?v=IQSknt4AqLw" target="_blank"><Typography variant="body1" color="#ffffff">A videóban megtekintheti a munka végeredményét.</Typography></Link>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default Repair