import StockElement from "../../components/StockElement";


function StockOpel() {
    return(
        <>
            <StockElement
                title="Opel szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Opel típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="OPEL SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="ADAM, AGILA, AMPERA, ASTRA, COMBO, CORSA, CROSSLAND, INSIGNIA,
                    MERIVA, MOKKA, MOVANO, OMEGA, VECTRA, VIVARO, ZAFIRA, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb OPEL típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockOpel