import StockElement from "../../components/StockElement";


function StockVolvo() {
    return(
        <>
            <StockElement
                title="Volvo szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Volvo típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="VOLVO SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="S40, V40, S60, V60, S80, V90, V40, V60, XC40, XC70, XC90, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb VOLVO típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockVolvo