import {Box, Button, Container, Link, Stack, Typography} from "@mui/material";
import Image from "../../assets/img/glassmax_kalkulator_logo.png";
import ImageMobile from "../../assets/img/glassmax_kalkulator_logo_mobile.png";
import React from "react";
import {useNavigate} from "react-router-dom";

function CalculatorThankYou() {

    const navigate = useNavigate();

    function handleClick() {
        navigate('/kalkulator.html')
    }

    return (
        <>
            <Container className="content" maxWidth="lg" sx={{paddingX: {xs: 0 }}}>
                <Stack spacing={5} flexDirection="column" justifyContent="center" justifyItems="center">
                    <Box textAlign="center">
                        <Box component="img" src={Image} alt="Stock picture" sx={{content: {xs: `url(${ImageMobile})`, md: `url(${Image})`}, height: 'auto', width: '70%' }} />
                    </Box>
                    <Stack spacing={3} flexDirection="column" justifyContent="center" justifyItems="center" textAlign="center">
                        <Box>
                            <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>KÖSZÖNJÜK</Typography>
                            <Box className="sep" sx={{marginX: 'auto', alignSelf: 'right'}}/>
                        </Box>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>A megadott adatok alapján, legkésőbb 1 munkanapon belül elküldjük Önnek
                            ajánlatunkat e-mailben. Kérjük, hogy a biztonság kedvéért ellenőrizze levelezése spam mappáját is!
                        </Typography>
                        <Button onClick={handleClick} sx={{borderRadius: 0,  alignSelf: 'center', background: '#000025', height: '40px', width: '200px', marginBottom: '10px', cursor: 'pointer', color: 'white', fontWeight: 'bold', '&:hover': {background: '#000025'} }}>
                            &lt; VISSZA
                        </Button>
                    </Stack>
                    <Box component="div" sx={{marginY: '1rem'}}/>
                </Stack>
            </Container>
        </>
    )
}

export default CalculatorThankYou