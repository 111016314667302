import StockElement from "../../components/StockElement";


function StockAlfaRomeo() {
    return(
        <>
            <StockElement title="Alfa Romeo szélvédő raktárról | Glassmax" meta="Szélvédők a legtöbb Alfa Romeo típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!" header="ALFA ROMEO SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="147, 156, 159, GIULIA, GIULIETTA, MITO, STELVIO, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb ALFA ROMEO típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockAlfaRomeo