import StockElement from "../../components/StockElement";


function StockHonda() {
    return(
        <>
            <StockElement
                title="Honda szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Honda típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="HONDA SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="ACCORD, CITY, CIVIC, CRV, FRV, HRV, INSIGHT, JAZZ, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb HONDA típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockHonda