import StockElement from "../../components/StockElement";


function StockMercedes() {
    return(
        <>
            <StockElement
                title="Mercedes szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Mercedes típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="MERCEDES SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="A OSZTÁLY, B OSZTÁLY, C OSZTÁLY, CLA, CLS, CLK, E OSZTÁLY, GLC, GLE,
                    ML, S OSZTÁLY, SL, SLK, SPRINTER, VITO, W123, W124, W140, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb MERCEDES típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockMercedes