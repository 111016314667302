import {FormWrapper} from "./FormWrapper"
import {Stack, Typography} from "@mui/material";
import {useState} from "react";
import Image1 from "../../assets/img/glassmax_kalkulator_elso.jpg";
import ImageMobile1 from "../../assets/img/glassmax_kalkulator_elso_mobile.jpg";
import Image2 from "../../assets/img/glassmax_kalkulator_hatso.jpg";
import ImageMobile2 from "../../assets/img/glassmax_kalkulator_hatso_mobile.jpg";
import Image3 from "../../assets/img/glassmax_kalkulator_oldalso.jpg";
import ImageMobile3 from "../../assets/img/glassmax_kalkulator_oldalso_mobile.jpg";
import CustomRadio from "../../components/CustomRadio";
import { useMediaQuery } from "@mui/material";

type PositionData = {
    position: string
}

type PositionFormProps = PositionData & {
    updateFields: (fields: Partial<PositionData>) => void
}

export function Form1({
                          position,
                          updateFields,
                      }: PositionFormProps) {

    const [selectedValue, setSelectedValue] = useState(position);

    const isSmallScreen = useMediaQuery("(max-width:600px)");

    const handleRadioChange = (value: string) => {
        setSelectedValue(value);
        updateFields({position: value})
    };

    return (
        <FormWrapper title="KÉRJEN AJÁNLATOT">
            <Typography sx={{typography: {sm: 'body1', xs: 'body2'}, fontWeight: {xs: 'bold', sm: 'bold'}}}>
                Kérjük válassza ki hogy melyik üveg sérült!
            </Typography>
            <Stack direction={{xs: 'column', md: 'row'}} spacing={5} alignItems="center" justifyContent="center">
                <CustomRadio
                    name="position"
                    value="first"
                    imageSrc={isSmallScreen ? ImageMobile1 : Image1}
                    checked={selectedValue === "first"}
                    onChange={() => handleRadioChange("first")}
                />
                <CustomRadio
                    name="position"
                    value="back"
                    imageSrc={isSmallScreen ? ImageMobile2 : Image2}
                    checked={selectedValue === "back"}
                    onChange={() => handleRadioChange("back")}
                />
                <CustomRadio
                    name="position"
                    value="side"
                    imageSrc={isSmallScreen ? ImageMobile3 : Image3}
                    checked={selectedValue === "side"}
                    onChange={() => handleRadioChange("side")}
                />
            </Stack>
        </FormWrapper>
    )
}