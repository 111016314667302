import {Box, Container, Link, Stack, Typography} from "@mui/material";
import Image from "../assets/img/glassmax_karrier_3.jpg";
import React from "react";
import {Helmet} from "react-helmet";

function Carrier () {
    return(
        <>
            <Helmet>
                <title>Szélvédő Javítás és Csere - Akár még ma - Glassmax</title>
                <meta name="description" content="Térjen vissza mielőbb az utakra. Kőfelverődés javítás német technológiával. Teljeskörű CASCO ügyintézés, 1 év garancia. Több mint 20 év tapasztalat." />
            </Helmet>
            <Container className="content" sx={{paddingX: {xs: 0 }}}>
                <Stack spacing={5} flexDirection="column" justifyContent="flex-start" justifyItems="flex-start">
                    <Box textAlign="center">
                        <Box component="img" src={Image} alt="Stock picture" sx={{content: `url(${Image})`, width: { xs: '100%', md: 'auto'}, height: 'auto' }} />
                    </Box>
                    <Stack spacing={3} flexDirection="column" justifyContent="flex-start" justifyItems="flex-start" textAlign="left">
                        <Box>
                            <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>KARRIER</Typography>
                            <Box className="sep"/>
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            Zöldterület karbantartót keresünk alkalmi munkavégzésre!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Jól jönne egy kis keresetkiegészítés?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Tudsz és szeretsz igényesen dolgozni?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Szereted, ha megbecsülik a munkádat?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Várunk egy jó csapatban!
                        </Typography>
                        <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>
                            Jelentkezz hozzánk ZÖLDTERÜLET KARBANTARTÓNAK!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            A munka jellege: alkalmi, havi kb. 16 óra, rugalmas beosztásban
                        </Typography>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Mi lesz a feladatod?
                        </Typography>
                        <ul>
                            <li>a cég ingatlanjain belül a zöldterület karbantartása (füves terület, sövény, fák,
                                bokrok)</li>
                            <li>az ingatlanok utcafrontjának rendben tartása, leginkább gazolás</li>
                        </ul>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Mit várunk tőled?
                        </Typography>
                        <ul>
                            <li>kétheti rendszerességgel tartsd karban telephelyeink zöld területeit</li>
                            <li>légy igényes a munkádra</li>
                            <li>légy megbízható</li>
                        </ul>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Karbantartandó területeink:
                        </Typography>
                        <ul>
                            <li>1154 Budapest Gábor Áron utca 112. (telephelyünk)</li>
                            <li>1155 Budapest Tóth István utca 1-3. (raktárunk)</li>
                            <li>1141 Budapest Szugló utca 132.</li>
                        </ul>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Bérezés megegyezés szerint.
                        </Typography>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            A munkához szükséges szerszámokat cégünk biztosítja.
                        </Typography>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Rólunk
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            A Glass Max Hungary Kft immáron több mint éve 20 éve meghatározó alakja a
                            magyar autó szélvédő piacnak. Saját szerelőműhellyel és szélvédő raktárral
                            rendelkezik, mely több mint 20.000 db terméknek biztosít helyet.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Felkeltettük érdeklődésed?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Hívj minket a <Link href="tel:+36303528091">+36 30 352 8091</Link> telefonszámon, és megbeszéljük a részleteket!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Örülünk, hogy megismerhetünk!
                        </Typography>
                   </Stack>
                </Stack>
            </Container>
        </>
    )
}

export default Carrier