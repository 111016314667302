import StockElement from "../../components/StockElement";


function StockSsangyong() {
    return(
        <>
            <StockElement
                title="SSANGYONG szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb SSANGYONG típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="SSANGYONG SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="ACTYON, KORANDO, REXTON, MUSSO, RODIUS, TIVOLI, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb SSANGYONG típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockSsangyong