import StockElement from "../../components/StockElement";


function StockNissan() {
    return(
        <>
            <StockElement
                title="Nissan szélvédő raktárról | Glassmax"
                meta="Szélvédők a legtöbb Nissan típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                header="NISSAN SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="ALMERA, JUKE, LEAF, MICRA, MURANO, NAVARA, NV200, NV400, QASHQAI,
                    TERRANO, TIIDA, X-TRAIL, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb NISSAN típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockNissan