import StockElement from "../../components/StockElement";


function StockBmw() {
    return(
        <>
            <StockElement title="BMW szélvédő raktárról | Glassmax"
                          meta="Szélvédők a legtöbb BMW típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!"
                          header="BMW SZÉLVÉDŐ ÉRTÉKESÍTÉS"
                          body="1-ES, 2-ES, 3-AS, 4-ES, 5-ÖS, 6-OS, 7-ES, 8-AS, I3, X1, X3, X5, X6, MINI
                    CLUBMAN, MINI COUNTRYMAN, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb BMW típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockBmw