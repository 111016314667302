import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img1 from "../assets/img/glassmax_banner_1.jpg"
import Img2 from "../assets/img/glassmax_banner_2.jpg"
import Img3 from "../assets/img/glassmax_banner_3.jpg"
import Img4 from "../assets/img/glassmax_biztositas_casco.jpg"
import Img5 from "../assets/img/glassmax_mobilszerviz_autok.jpg"
import ImgMoblie1 from "../assets/img/glassmax_banner_mobile_1.jpg"
import ImgMoblie2 from "../assets/img/glassmax_banner_mobile_2.jpg"
import ImgMoblie3 from "../assets/img/glassmax_banner_mobile_3.jpg"
import ImgMoblie4 from "../assets/img/glassmax_biztositas_casco_mobile.jpg"
import ImgMoblie5 from "../assets/img/glassmax_mobilszerviz_autok_mobile.jpg"
import {Box} from "@mui/material";
import RightIcon from "../assets/icon/right.png";
import LeftIcon from "../assets/icon/left.png";

function Carousel() {

    function SampleNextArrow(props: { className?: any; style?: any; onClick?: any; }) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", backgroundImage: `url(${RightIcon})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat'}}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props: { className?: any; style?: any; onClick?: any; }) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style,  display: "block", backgroundImage: `url(${LeftIcon})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', width: '40px', height: '40px' }}
                onClick={onClick}
            />
        );
    }


    var settings = {
        //dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        //nextArrow: <SampleNextArrow />,
        //prevArrow: <SamplePrevArrow />
    };
    return(
        <Slider {...settings}>
            <div>
                <Box component="img" alt="First slide" sx={{content: {xs: `url(${ImgMoblie1})`, md: `url(${Img1})`}, width: '100%', height: 'auto' }} />
            </div>
            <div>
                <Box component="img" alt="Second slide" sx={{content: {xs: `url(${ImgMoblie2})`, md: `url(${Img2})`}, width: '100%', height: 'auto' }} />
            </div>
            <div>
                <Box component="img" alt="Third slide" sx={{content: {xs: `url(${ImgMoblie3})`, md: `url(${Img3})`}, width: '100%', height: 'auto' }} />
            </div>
            <div>
                <Box component="img" alt="Biztosítás ügyintésés a Glassmax irodájában" sx={{content: {xs: `url(${ImgMoblie4})`, md: `url(${Img4})`}, width: '100%', height: 'auto' }} />
            </div>
            <div>
                <Box component="img" alt="A Glassmax mobilszerviz autói indulásra készen" sx={{content: {xs: `url(${ImgMoblie5})`, md: `url(${Img5})`}, width: '100%', height: 'auto' }} />
            </div>
        </Slider>
    )

}
export default Carousel