import React, {useEffect} from "react"
import axios from "axios"
import data from "../data/faq.json"
import {Box, Container, Paper, Stack, Typography} from "@mui/material"
import backgroundImage from "../assets/img/glassmax_gyik_background.jpg"
import backgroundImageTitle from "../assets/img/gradient.png"
import backgroundImageTitleMobile from "../assets/img/gradient_mobile.png"
import {Helmet} from "react-helmet";
function FAQ(){
    /*useEffect(() => {
        // Itt hajtsd végre az API-hívást
        axios.get('https://jsonplaceholder.typicode.com/todos')
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);*/
    return(
        <>
            <Helmet>
                <title>Gyakori szélvédős kérdések | Glassmax</title>
                <meta name="description" content="Rövid kérdések és válaszok, amelyeket ügyfeleink legtöbbször feltesznek a javítás és csere kapcsán. Tudjon meg többet!" />
            </Helmet>
            <Container className="content" maxWidth={false} sx={{backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'noRepeat', paddingY: '20px', marginTop: '60px'}}>
                <Stack spacing={5}>
                {data.faq.map((faq) => (
                        <Paper sx={{ textAlign: 'center' }}>
                            <Box sx={{
                                backgroundImage: { xs: `url(${backgroundImageTitleMobile})`, md: `url(${backgroundImageTitle})`},
                                backgroundPosition: 'center',
                                backgroundSize: { xs: 'contain', md: 'cover'},
                                backgroundRepeat: 'noRepeat',
                            }}>
                                <Typography sx={{typography: { md: 'h5', xs: 'body1' }, color:'#ffffff', paddingY: '20px', fontWeight: "bold"}} >{faq.title}</Typography>
                            </Box>
                            <Box>
                                <Typography variant="body1" sx={{paddingY: '20px'}}>{faq.body}</Typography>
                            </Box>
                        </Paper>
                ))}
                </Stack>
            </Container>
        </>
    )
}

export default FAQ