import { FormWrapper } from "./FormWrapper"
import {Box, Container, Grid, Stack, Typography} from "@mui/material";
import React, {useState} from "react";

type PersonalData = {
    position: string
    size?: string
    location: string
    postcode?: string
    settlement?: string
    address?: string
    casco: string
    insurance?: string
    plateNumber: string
    vin?: string
    manufacturer: string
    type: string
    year: string
    personOrCompany: string
    name: string
    phone: string
    email: string
    privacyPolicy: boolean
}


type SummaryFormProps = PersonalData & {
    updateFields: (fields: Partial<PersonalData>) => void
}

export function Form7({
                          position,
                          size,
                          location,
                          postcode,
                          settlement,
                          address,
                          casco,
                          insurance,
                          plateNumber,
                          vin,
                          manufacturer,
                          type,
                          year,
                          personOrCompany,
                          name,
                          phone,
                          email,
                          privacyPolicy,
                          updateFields,
                      }: SummaryFormProps) {

    const [selectedValue, setSelectedValue] = useState(privacyPolicy);

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.checked);
        updateFields({privacyPolicy: event.target.checked})
    };


    return (
        <FormWrapper title="KALKULÁCIÓ - ADATOK ELLENŐRZÉSE">
            <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: { xs:'bold', sm:'bold'}}}>
                Kérjük, ellenőrizze a megadott adatokat!
            </Typography>
            <Container style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <Stack direction="column" spacing={1}>
                <Grid container className="row justify-content-start" sx={{textAlign: 'left'}}>
                    <Grid item xs={12} sx={{background: '#000025', height: '50px', color: 'white'}}>
                        <Typography variant='h6' sx={{fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-title">Sérülés adatai</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Üveg pozíció</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="position"
                                    className="calculator-summary-value">{position==='first'?'Első szélvédő':position==='back'?'Hátsó szélvédő':'Oldalsó üveg'}</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Sérülés mérete</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="size"
                                    className="calculator-summary-value">{size==='small'?'Száz forintosnál kisebb':'Száz forintosnál nagyobb'}</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Javítás helye</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="location"
                                    className="calculator-summary-value">{location==='site'?'A Glassmax telephelyén':'Az ügyfélnél - '+postcode+' '+settlement+', '+address }</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Casco</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="casco"
                                    className="calculator-summary-value">{casco==='no'?'Nincs':'Van - '+insurance}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{background: '#000025', height: '50px', color: 'white'}}>
                        <Typography variant='h6' sx={{fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-title">Gépjármű adatatok</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Rendszám</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="plateNumber"
                                    className="calculator-summary-value">{plateNumber}</Typography>
                    </Grid>
                    {(size == null || size==='big') && vin && <><Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Alvázszám</Typography>
                    </Grid><Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="vin"
                                    className="calculator-summary-value">{vin}</Typography>
                    </Grid></>}
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Jármű gyártmánya</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="manufacturer"
                                    className="calculator-summary-value">{manufacturer}</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Jármű típusa</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="type"
                                    className="calculator-summary-value">{type}</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Évjárat</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="year"
                                    className="calculator-summary-value">{year}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{background: '#000025', height: '50px', color: 'white'}}>
                        <Typography variant='h6' sx={{fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-title">Személyes adatok</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Magánszemély / Cég</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="person_or_company"
                                    className="calculator-summary-value">{personOrCompany==='person'?'Magánszemély':'Cég'}</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Név</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="name"
                                    className="calculator-summary-value">{name}</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Telefonszám</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="phone"
                                    className="calculator-summary-value">+36 {phone}</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'bold', padding: '12px'}}
                                    className="calculator-summary-key">Email</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{
                        height: '50px',
                        borderBottomColor: '#000025',
                        borderBottomWidth: 'thin',
                        borderBottomStyle: 'solid'
                    }}>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2'}, fontWeight: 'normal', padding: '12px'}} id="email"
                                    className="calculator-summary-value">{email}</Typography>
                    </Grid>
                 </Grid>
                <Grid container className="col-12" sx={{textAlign: 'left'}} spacing={4}>
                    <Grid item xs={12}>
                        <input type="checkbox" checked={selectedValue} onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="checkPrivacyPolicy">
                            Elolvastam és elfogadom az <a target="_blank" href="adatvedelmi.pdf">Adatvédelmi
                            tájékoztatót</a>.
                        </label>
                    </Grid>


                </Grid>
                </Stack>
            </Container>
        </FormWrapper>
    )
}