import DoYouKnowDetail from "./DoYouKnowDetail";
import Image from "../assets/img/glassmax_tudta_full_5.jpg"

function DoYouKnowAfterReplacement() {
    return(
        <>
            <DoYouKnowDetail
                title="Mi a teendő szélvédőcsere után | Glassmax"
                meta="Mit tegyünk, és mit ne tegyünk a szélvédőcserét követő 48 órában? Száradási idő, mosás és a rögzítő szalagok eltávolítása. Tartsuk be a szükséges lépéseket!"
                imageUrl={Image}
                header="Szélvédőcsere után - utógondozási tippek:"
                text="<p>Az újonnan beszerelt üvegek kezelésének nagy szerepe van a szélvédő hosszú élettartamában, újszerű állapotának megőrzésében:</p>
                <p>Mit tegyünk?</p>
                <p>
                <ul>
                    <li>Várjuk ki a szükséges időt, mielőtt elindulnánk az autóval. Ez általában 1-2 órát jelent, amíg a ragasztó megköt és teljesen
                        megszárad. A Glass Max-nál minden esetben, egyedileg tájékoztatjuk ügyfeleinket a pontos időtartamról.
                    </li>
                    <li>Tartsuk kissé lehúzva az ablakokat. A légnyomás extra terhelést jelenthet száradás közben a szélvédő körüli tömítésre.
                        A megemelkedett  belső légnyomás a szélvédőrepedések egyik leggyakoribb oka. Ezt kerülhetjük el,
                        ha a szélvédő beszerelését követő napon egyszerűen lehúzva tartjuk az ablakokat.
                    </li>
                    <li>Bánjunk körültekintően az új szélvédővel. Óvatosan nyissuk és csukjuk az ajtókat, és lehetőleg ne használjuk az ablaktörlőket az első órákban.
                    </li>
                    <li>Mindig vezessünk óvatosan, de közvetlenül szélvédőcsere után erre különösen figyeljünk. Kerüljük a kátyúkat és a hirtelen fékezést.
                    </li>
                </ul>
                </p>
                    <p>Mit ne tegyünk?</p>
                    <p>
                    <ul>
                        <li>Várjunk legalább 48 órát, mielőtt autómosóba vinnénk az autót, ne használjunk semmilyen magasnyomású mosót.
                            Ez idő alatt a szélvédő ragasztó teljesen megszárad, az új keretek pedig tökéletesen illeszkedni fognak.
                        </li>
                        <li>A Glass Max-nál, a minél tökéletesebb végeredmény érdekében rögzítő szalagot használunk a keretek rögzítésére.
                            Ne távolítsuk el ezt a szalagot az első 24-48 órában.
                        </li>
                        <li>Ne helyezzünk semmilyen burkolatot az autóra. A beszerelést követő első 24 órában kerüljük a burkolat használatát
                            a jármű külső oldalán, illetve a napernyő használatát a szélvédő belső oldalán.
                        </li>
                    </ul>
                    </p>
                <p>Az alábbi tippek betartásával biztosíthatjuk, hogy új szélvédőnk repedések nélkül a helyén maradjon.
                </p>
                    <p>Kérdése van csapatunkhoz? Keressen minket elérhetőségeinken!
                    </p>"/>
        </>
    )
}

export default DoYouKnowAfterReplacement