import StockElement from "../../components/StockElement";


function StockAudi() {
    return(
        <>
            <StockElement title="Audi szélvédő raktárról | Glassmax" meta="Szélvédők a legtöbb Audi típushoz, évjárathoz, raktárról, azonnal. Raktárkészletünkről a ritkább gyártmányok is elérhetők. Ajánlatért hívjon bennünket!" header="AUDI SZÉLVÉDŐ ÉRTÉKESÍTÉS" body="A1, A3, A4, A5, A6, A7, A8, Q3, Q5, Q7, Q8, és még folytathatnánk a sort.<br>
                    Nálunk a legtöbb AUDI típushoz és évjárathoz talál szélvédőt, azonnal raktárról.<br>
                    Óriási raktárkészletünk kínálatában a régebbi és ritkább modellek is megtalálhatóak." />
        </>
    )
}

export default StockAudi